import { ExternalLink } from '../SidenavAccount.styles';

import {
  SidenavDropdown,
  SidenavDropdownItem,
  SidenavDropdownItemLabel,
  SidenavItemTitle,
} from '@aircall/tractor-v2';
import { USERS_ROUTE, USER_DETAIL_ROUTE_GENERAL_TAB } from '@constants/routes.constants';
import { Avatar, getFullName, getInitials } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useCurrentUser } from '@hooks/useCurrentUser/useCurrentUser';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useReseller } from '@hooks/useReseller/useReseller';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

export function ProfileRoutes({ expanded }: Readonly<{ expanded: boolean }>) {
  const location = useLocation();

  const currentPath = location.pathname;

  const { showReferAFriendButton } = useFeaturesFlags();

  const { isReseller } = useReseller();

  const {
    actions: { signOut },
  } = useAuthenticationState();

  const { currentUser } = useCurrentUser();

  const { t } = useTranslation();

  const displayReferAFriendButton = showReferAFriendButton && !isReseller;

  if (!currentUser) {
    return null;
  }

  const currentUserRoute = USER_DETAIL_ROUTE_GENERAL_TAB(currentUser.ID);
  const isCurrentUserRouteActive =
    currentPath.includes(currentUser.ID) && currentPath.includes(USERS_ROUTE);

  return (
    <SidenavDropdown
      indicator={
        <Avatar
          variant='default'
          initials={getInitials(currentUser.firstName, currentUser.lastName)}
          src={currentUser.pictureURL}
        />
      }
      title={
        <SidenavItemTitle>
          {getFullName(currentUser.firstName, currentUser.lastName)}
        </SidenavItemTitle>
      }
      tooltip={getFullName(currentUser.firstName, currentUser.lastName)}
      expanded={expanded}
      active={isCurrentUserRouteActive}
      component='button'
      data-test='profile-dropdown'
    >
      <SidenavDropdownItem
        component={NavLink}
        active='current-page'
        to={currentUserRoute}
        data-test='profile-edit-profile'
      >
        <SidenavDropdownItemLabel>{t('sidenav.profile.edit_profile')}</SidenavDropdownItemLabel>
      </SidenavDropdownItem>
      {displayReferAFriendButton ? (
        <SidenavDropdownItem
          component={ExternalLink}
          to={t('sidenav.profile.refer_friend_url')}
          data-test='profile-refer-friend'
        >
          <SidenavDropdownItemLabel>{t('sidenav.profile.refer_friend')}</SidenavDropdownItemLabel>
        </SidenavDropdownItem>
      ) : null}
      <SidenavDropdownItem component='button' data-test='profile-log-out' onClick={signOut}>
        <SidenavDropdownItemLabel>{t('sidenav.profile.logout')}</SidenavDropdownItemLabel>
      </SidenavDropdownItem>
    </SidenavDropdown>
  );
}
