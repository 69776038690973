import styled from 'styled-components';

import * as RoutesConstants from '../../constants/routes.constants';

import { getColor } from '@aircall/tractor-v2';
import { DynamicAssetComponent } from '@components/DynamicAssetComponent';

export const DEFAULT_PAGE = RoutesConstants.NUMBERS_ROUTE;

export const SidebarWrapper = styled.nav`
  display: grid;
  width: 260px;
  min-width: 260px;
  height: 100%;
  border-right: 1px solid ${getColor('neutral-200')};
  grid-template-columns: 100%;
  grid-template-rows: max-content auto max-content;
`;

export const SidebarBottomLogo = styled(DynamicAssetComponent)`
  padding: 10px;
  border: 2px solid red;
`;
