import { FeaturesFlagsQuery_featureFlags } from '@generated/FeaturesFlagsQuery';

export const ONBOARDING_TRIAL_DAILY_CALL_LIMIT_MINUTES = 100;
export const DEFAULT_DAILY_CALL_LIMIT_MINUTES = 40;

type FeatureFlagsType = Omit<FeaturesFlagsQuery_featureFlags, '__typename'>;

export type FeaturesFlagsData = {
  [K in keyof FeatureFlagsType]: NonNullable<FeatureFlagsType[K]>;
};

export type CustomFeaturesFlags = {
  dailyCallLimitInMinutes: number;
  showReferAFriendButton: boolean;
  smartflowsEnabled: boolean;
  smartflowMigrationEnabled: boolean;
  enableSaml: boolean;
  showAnalyticsPlus: boolean;
};

export type FeaturesFlags = Partial<FeaturesFlagsData> & CustomFeaturesFlags & { loading: boolean };
