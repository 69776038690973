/* eslint-disable @typescript-eslint/naming-convention */
import { logger } from '@config/logger.config';
import {
  FACEBOOK_SDK_URL,
  META_APP_ID,
  META_CONFIG_ID,
  META_GRAPH_API_VERSION,
  // META_SOLUTION_ID,
} from '@constants/environment.constants';

export const loadFacebookSDKScript = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const scriptId = 'facebook-jssdk';
    if (document.getElementById(scriptId)) {
      resolve();
    } else {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: META_APP_ID,
          version: META_GRAPH_API_VERSION,
        });
        resolve();
      };

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = FACEBOOK_SDK_URL;
      script.async = true;
      script.onerror = (err) => {
        logger.error('Facebook SDK | Script Load | Error', err);
        reject(new Error('Facebook SDK failed to load'));
      };
      document.body.appendChild(script);
    }
  });

export const launchEmbeddedSignup = (): void => {
  if (!window.FB) {
    return;
  }

  window.FB.login(() => {}, {
    config_id: META_CONFIG_ID,
    auth_type: 'rerequest',
    response_type: 'code',
    override_default_response_type: true,
    extras: {
      sessionInfoVersion: 2, // Required to get WABA ID
      // Uncomment this once app is approved by Meta
      // setup: {
      //   solutionID: META_SOLUTION_ID,
      // },
    },
  });
};

export const getEmbeddedSignupListener =
  (
    onEmbeddedSignup: (phoneNumberId: string, wabaId: string) => void
  ): ((event: MessageEvent) => void) =>
  (event: MessageEvent) => {
    if (!event.origin.endsWith('facebook.com')) {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow and the popup window closes
        if (data.event === 'FINISH' || data.event === 'FINISH_ONLY_WABA') {
          const { phone_number_id, waba_id } = data.data;
          logger.info('Facebook SDK | Embedded Signup | Finish', data);
          onEmbeddedSignup(phone_number_id, waba_id);
        } else {
          const { current_step } = data.data;
          logger.info(`Facebook SDK | Embedded Signup | Not finished - last step: ${current_step}`);
        }
      }
    } catch (err) {
      logger.error('Facebook SDK | Embedded Signup | Error', { errorData: err });
    }
  };
