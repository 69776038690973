import { OnboardingContentSection, OnboardingContentSectionV2, PlansModal } from './components';

import { useContractCheckAndSign } from '@components/LandingPage/hooks/useContractCheckAndSign';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';

export function OnboardingPage() {
  const { showOnboardingTrial, dashboardEnablePricingAwareness } = useFeaturesFlags();

  useContractCheckAndSign();

  // We want to show the plans modal if pricing awareness is enabled
  const showPlansModal = dashboardEnablePricingAwareness;

  return (
    <>
      {showOnboardingTrial ? <OnboardingContentSectionV2 /> : <OnboardingContentSection />}
      {showPlansModal ? <PlansModal /> : undefined}
    </>
  );
}
