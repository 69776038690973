/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { CompanyFragment } from '@generated/CompanyFragment';
import { clearLoggerContext, setLoggerContext } from '@helpers/logging.helpers';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { CurrentUser } from '@hooks/useCurrentUser/useCurrentUser.decl';

/**
 * This hook is not a general purpose hook and its made essentially to set context to
 * be tracked by our error logger, this means configuring the scope in which the application
 * is running so we can filter errors in the future. So it is meant to be only inside
 * the GlobalProvider where the data for the current user and company fetched
 */

export function useLogger(currentUser?: CurrentUser, currentCompany?: CompanyFragment): void {
  const {
    authState: { decodedToken },
  } = useAuthenticationState();
  const adminImpersonator = decodedToken?.['custom:admin_sso_id'];
  useEffect(() => {
    if (currentUser && currentCompany) {
      let extraContext = {};
      if (adminImpersonator) {
        extraContext = {
          connect_as: {
            admin_sso_id: adminImpersonator,
            impersonated_user: currentUser.ID,
          },
        };
      }
      setLoggerContext(
        {
          company_id: currentCompany.id,
          user_id: currentUser.ID,
          is_trial: !!currentCompany.trial,
          tier_level: currentCompany.tierLevel,
          provider: currentCompany.provider,
        },
        extraContext
      );
    }

    return () => {
      clearLoggerContext();
    };
  }, [adminImpersonator, currentCompany, currentUser]);
}
