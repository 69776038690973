import { ACPusherProviderProps } from './PusherProvider.decl';

import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import { AIRCALL_API_URL, PUSHER_API_KEY } from '@constants/environment.constants';
import { Loading, UnknownError } from '@dashboard/library';
import { GetPusherConfigQuery } from '@generated/GetPusherConfigQuery';
import { UpdatePusherConfigSubscription } from '@generated/UpdatePusherConfigSubscription';
import { GET_PUSHER_CONFIG } from '@graphql/queries/GetPusherConfig';
import { UPDATE_PUSHER_CONFIG_SUBSCRIPTION } from '@graphql/subscriptions/UpdatePusherConfigSubscription';
import { PusherProvider, PusherProviderProps as PusherProviderOptions } from '@harelpls/use-pusher';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { customHandlerFactory } from '@services/customHandlerFactory';

export function ACPusherProvider({ children }: ACPusherProviderProps) {
  const { enablePusherConfig } = useFeaturesFlags();
  const {
    authState: { token },
  } = useAuthenticationState();

  const apolloClient = useApolloClient();

  const {
    data: pusherDynamicConfig,
    loading,
    error: getPusherConfigError,
  } = useQuery<GetPusherConfigQuery>(GET_PUSHER_CONFIG, {
    skip: !enablePusherConfig,
  });
  const { error: updatePusherConfigError } = useSubscription<UpdatePusherConfigSubscription>(
    UPDATE_PUSHER_CONFIG_SUBSCRIPTION,
    {
      skip: !enablePusherConfig,
      onSubscriptionData: ({ client, subscriptionData: { data } }) => {
        // Might not be necessary TBT
        if (data?.onNotificationsConfigurationChange) {
          client.writeQuery<GetPusherConfigQuery>({
            query: GET_PUSHER_CONFIG,
            data: {
              getNotificationsConfiguration: {
                __typename: 'NotificationsConfiguration',
                appKey: data.onNotificationsConfigurationChange.appKey,
                cluster: data.onNotificationsConfigurationChange.cluster,
              },
            },
          });
        }
      },
    }
  );

  if (loading) {
    return <Loading data-test='loading' />;
  }

  if (getPusherConfigError) {
    throw new UnknownError('Fail to get pusher config.', { getPusherConfigError });
  }

  if (updatePusherConfigError) {
    throw new UnknownError('Fail to update pusher config.', { updatePusherConfigError });
  }

  if (enablePusherConfig && !pusherDynamicConfig) {
    throw new UnknownError('Missing pusher config.', { pusherDynamicConfig });
  }

  const pusherConfig: PusherProviderOptions = enablePusherConfig
    ? {
        clientKey: pusherDynamicConfig!.getNotificationsConfiguration.appKey,
        cluster: pusherDynamicConfig!.getNotificationsConfiguration.cluster,
        channelAuthorization: {
          endpoint: `${AIRCALL_API_URL}pusher/auth`,
          transport: 'ajax',
          customHandler: customHandlerFactory(
            apolloClient,
            pusherDynamicConfig?.getNotificationsConfiguration.cluster as string
          ),
        },
      }
    : {
        clientKey: PUSHER_API_KEY,
        cluster: 'mt1',
        authEndpoint: `${AIRCALL_API_URL}pusher/auth`,
        auth: {
          headers: { Authorization: `Bearer ${token}` },
        },
      };

  return (
    <PusherProvider key={token} {...pusherConfig}>
      {children}
    </PusherProvider>
  );
}
