import { useLayoutEffect } from 'react';

import { Flex } from '@aircall/tractor-v2';
import { Loading } from '@dashboard/library';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useLocation } from 'react-router';

type LocationState = {
  fromPasswordScreen?: boolean;
};

export function OnboardingTrialWrapper({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const state = location.state as LocationState;
  const { showOnboardingTrial } = useFeaturesFlags();
  const shouldRedirectToWorkspace = showOnboardingTrial && state?.fromPasswordScreen;

  useLayoutEffect(() => {
    if (shouldRedirectToWorkspace) {
      window.location.href = 'https://workspace.aircall.io';
    }
  }, [shouldRedirectToWorkspace]);

  if (shouldRedirectToWorkspace) {
    return (
      <Flex minHeight='100%' alignItems='center' justifyContent='center'>
        <Loading data-test='loading-workspace-redirection' />;
      </Flex>
    );
  }

  return children;
}
