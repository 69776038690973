import { useCallback, useEffect, useState } from 'react';

import {
  MUSIC_ON_HOLD_AUDIO_TYPE,
  MusicOnHoldFormValues,
} from '../MusicOnHoldSettings/MusicOnHoldSettings.decl';
import { getMusicOnHoldAudioSetting } from '../MusicOnHoldSettings/MusicOnHoldSettings.helpers';

import { useAudioLibrary } from '@hooks/useAudioLibrary/useAudioLibrary';
import { useNumberDetail } from '@hooks/useNumberDetail';

interface UseMusicOnHoldAudioSettingsType {
  audioSettings?: MusicOnHoldFormValues['audioSetting'];
  updateAudioSetting: () => void;
}

export function useMusicOnHoldAudioSettings(): UseMusicOnHoldAudioSettingsType {
  const { number } = useNumberDetail();
  const { tracks } = useAudioLibrary();
  const [audioSettings, setAudioSettings] = useState<MusicOnHoldFormValues['audioSetting']>();

  const updateAudioSetting = useCallback(async () => {
    const audioSetting = await getMusicOnHoldAudioSetting(number!.musicOnHold);

    if (audioSetting.type === MUSIC_ON_HOLD_AUDIO_TYPE.FILE) {
      setAudioSettings(audioSetting);
      return;
    }

    const isErroneousTrack =
      Boolean(tracks) && !tracks?.find((track) => track.url === audioSetting.url);

    if (isErroneousTrack) {
      const fileName = audioSetting.url.slice(audioSetting.url.lastIndexOf('/') + 1);

      const blob = await fetch(audioSetting.url).then((res) => res.blob());
      const file = new File([blob], fileName);

      setAudioSettings({
        file,
        type: MUSIC_ON_HOLD_AUDIO_TYPE.FILE,
      });
      return;
    }

    setAudioSettings(audioSetting);
  }, [number, tracks]);

  useEffect(() => {
    updateAudioSetting();
  }, [updateAudioSetting]);

  return { audioSettings, updateAudioSetting };
}
