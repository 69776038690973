import { Spacer } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { MetaEmbeddedSignupButton } from '@components/MetaEmbeddedSignupButton';
import { WHATSAPP_INTEGRATION_DETAILS_URL } from '@constants/urls.constants';
import { Paper } from '@dashboard/library';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { Trans, useTranslation } from 'react-i18next';

export function NumberDetailWhatsAppIntegrationSection() {
  const { t } = useTranslation();
  const { enableWhatsappIntegration } = useFeaturesFlags();

  const onEmbeddedSignup = (phoneNumberId: string, wabaId: string) => {
    // WABA ID will be used to create the Twilio Sender on backend
    // eslint-disable-next-line no-console
    console.log('onEmbeddedSignup', phoneNumberId, wabaId);
  };

  return enableWhatsappIntegration ? (
    <Paper
      title={t('number_details.integrations.whatsapp_integration.title')}
      subtitle={
        <Trans i18nKey='number_details.integrations.whatsapp_integration.subtitle'>
          You will be redirected to Meta Business to configure number.
          <ExternalLink
            href={WHATSAPP_INTEGRATION_DETAILS_URL}
            target='_blank'
            data-test='whatsapp-integration-more-details'
          >
            Click here for more details
          </ExternalLink>
        </Trans>
      }
    >
      <Spacer fluid direction='vertical' space='xxxs'>
        <MetaEmbeddedSignupButton onEmbeddedSignup={onEmbeddedSignup} />
      </Spacer>
    </Paper>
  ) : null;
}
