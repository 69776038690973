/* eslint-disable @typescript-eslint/naming-convention */
import { ReactElement } from 'react';

import { ReactComponent as AircallAIBadge } from '@assets/icons/aircall_ai_badge.svg';
import { ReactComponent as AnalyticsPlusBadge } from '@assets/icons/analytics_plus_badge.svg';
import { ADDONS } from '@constants/addons.constants';
import { STATS_ROUTE, STATS_CHILD_ROUTE } from '@constants/routes.constants';

export interface SidenavItemsAddonsConfig {
  addonName: ADDONS;
  badge: ReactElement;
  includedChildren?: string[];
}

export const SIDENAV_ITEMS_ADDONS_CONFIG: Record<string, SidenavItemsAddonsConfig> = {
  [STATS_ROUTE]: {
    addonName: ADDONS.ANALYTICS_PLUS,
    badge: <AnalyticsPlusBadge />,
    includedChildren: [
      STATS_CHILD_ROUTE.ANALYTICS_PLUS,
      STATS_CHILD_ROUTE.USERS_ACTIVITY,
      STATS_CHILD_ROUTE.INBOUND_ACTIVITY,
      STATS_CHILD_ROUTE.OUTBOUND_ACTIVITY,
      STATS_CHILD_ROUTE.UNANSWERED_CALLS,
      STATS_CHILD_ROUTE.MONITORING,
      STATS_CHILD_ROUTE.AVAILABILITIES,
      STATS_CHILD_ROUTE.CALL_QUALITY,
    ],
  },
  '/conversations': {
    addonName: ADDONS.AIRCALL_AI,
    badge: <AircallAIBadge />,
  },
};
