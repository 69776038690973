import styled from 'styled-components';

import { Box, Button, Typography, Flex, Spacer } from '@aircall/tractor-v2';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const FireworksCanvas = styled(Fireworks)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface Props {
  readonly includeConfetti?: boolean;
}

export function OnboardingCelebration({ includeConfetti = true }: Props) {
  const {
    currentUser: { firstName },
  } = useGlobalData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onUpgradeNow = () => {
    navigate('/account/plan');
  };

  return (
    <Flex
      data-test='onboarding-celebration'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      backgroundColor='rgba(247, 247, 247, 0.8)'
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      {includeConfetti ? (
        <Flex
          data-test='onboarding-confetti'
          position='absolute'
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          <FireworksCanvas
            autorun={{ speed: 0.35, duration: 10000 }}
            globalOptions={{
              disableForReducedMotion: true,
            }}
          />
        </Flex>
      ) : undefined}

      <Box
        zIndex={1}
        maxWidth='440px'
        padding='s'
        borderRadius='default'
        backgroundColor='neutral-0'
      >
        <Spacer direction='vertical' space='xl' alignItems='center'>
          <Spacer direction='vertical' alignItems='center' space='s'>
            <Typography variant='headingBoldS' textAlign='center'>
              {t('onboarding.celebration.celebration1', { name: firstName })}
              <br />
              {t('onboarding.celebration.celebration2')}
            </Typography>

            <Typography color='text.dark' variant='bodyRegularM' textAlign='center'>
              {t('onboarding.celebration.upgrade_description')}
            </Typography>
          </Spacer>

          <Button data-test='upgrade-now-btn' onClick={onUpgradeNow}>
            {t('onboarding.celebration.upgrade_now')}
          </Button>
        </Spacer>
      </Box>
    </Flex>
  );
}
