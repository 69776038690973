import {
  AdminFeatures,
  MAX_NUMBERS_OF_NUMBERS_IN_TRIAL,
  MAX_NUMBER_OF_TEAMS_ADVANCED,
} from './useAdminFeatures.decl';

import { ResellerConfig } from '@config/resellers.config';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useReseller } from '@hooks/useReseller/useReseller';

// Admin features that are not dependent on the FeaturesFlags query call
export function useAdminFeatures(): AdminFeatures {
  const { currentCompany, currentPlan, isTrial } = useGlobalData();
  const { isReseller } = useReseller();

  const {
    featuresAllowed: {
      isTeamsAdvancedAllowed,
      isQueueCallbackAllowed,
      isMandatoryTaggingAllowed,
      isSipSettingsAllowed,
      isCompanyAccountAllowed,
      isSupportLinksAllowed,
      allow10NumbersInTrial,
      isAdvancedAnalyticsAllowed,
      isLiveFeedAllowed,
    },
  } = currentCompany;

  const {
    featureSet: { shouldShowCompanyAccountPage, shouldShowHelpLinks },
  } = ResellerConfig;

  const maxNumberOfTeams = isTeamsAdvancedAllowed
    ? MAX_NUMBER_OF_TEAMS_ADVANCED
    : currentPlan.features.nbTeamsMax;

  /**
   * This is the setting that will check the max number of numbers
   * if the company has a feature flag active to allow at least 10
   * numbers we will set the amount of numbers as 10 or we get the amount from the plan.
   */
  const maxNumberOfNumbers =
    isTrial && allow10NumbersInTrial
      ? MAX_NUMBERS_OF_NUMBERS_IN_TRIAL
      : currentPlan.features.nbLinesMax;

  return {
    isAdvancedAnalyticsAllowed,
    isSipSettingsAllowed,
    isLiveFeedAllowed,
    isMandatoryTaggingAllowed,
    isQueueCallbackAllowed,
    maxNumberOfTeams,
    maxNumberOfNumbers,
    isCompanyAccountAllowed: shouldShowCompanyAccountPage && isCompanyAccountAllowed && !isReseller,
    isSupportLinksAllowed: shouldShowHelpLinks && isSupportLinksAllowed,
  };
}
