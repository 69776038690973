import type { SidenavFooterProps } from './SidenavFooter.decl';
import { AircallIconContainer, Footer } from './SidenavFooter.styles';

import { ReactComponent as AircallLogo } from '@assets/images/aircall_sidenav_logo.svg';

export function SidenavFooter({ expanded }: Readonly<SidenavFooterProps>) {
  return (
    <Footer
      pt='xxxs'
      pb='xxs'
      px='xxs'
      alignItems='center'
      position='relative'
      $expanded={expanded}
      minH='30px'
    >
      <AircallIconContainer flexShrink={0}>
        <AircallLogo />
      </AircallIconContainer>
    </Footer>
  );
}
