import { AnalyticsDashboard } from '@components/AnalyticsDashboard/AnalyticsDashboard';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { ANALYTICS_LIMITS } from '@helpers/analytics.helpers';
import { useTranslation } from 'react-i18next';

export function StatsCallQualityProPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsDashboard
      feedbackButtonEvent='call_quality_analytics_feedback_button_clicked'
      minDate={ANALYTICS_LIMITS.sixMonthDate}
      pageHeaderTitle={t('stats.tabs.network_diagnostics_pro')}
      title='call_quality_pro'
      type={AnalyticsDashboardType.CALL_QUALITY_PRO}
    />
  );
}
