import { SidenavDropdown as BaseSidenavDropdown, Box } from '@aircall/tractor-v2';
import { paddingInlineEndForOutlineOffset } from '@components/Sidenav/Sidenav.utils';
import styled, { getSpace } from '@xstyled/styled-components';

export const SidenavDropdown = styled(BaseSidenavDropdown)`
  gap: ${getSpace('xxs')};
  padding-inline: 8px;
`;

export const Container = styled(Box)`
  ${paddingInlineEndForOutlineOffset}
`;
