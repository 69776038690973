import type { SidenavHeaderProps } from './SidenavHeader.decl';
import { Header, ToggleButton } from './SidenavHeader.styles';

import { ChevronLeftOutlined } from '@aircall/icons';
import { useTranslation } from 'react-i18next';

export function SidenavHeader({ expanded, toggleExpanded }: Readonly<SidenavHeaderProps>) {
  const { t } = useTranslation();

  return (
    <Header
      pt='xxs'
      pr='xxs'
      alignItems='center'
      position='relative'
      as='header'
      $expanded={expanded}
    >
      <ToggleButton
        shape='circle'
        size='xSmall'
        variant='secondary'
        mode='outline'
        aria-label={expanded ? t('app.collapse_navigation') : t('app.expand_navigation')}
        onClick={toggleExpanded}
      >
        <ChevronLeftOutlined />
      </ToggleButton>
    </Header>
  );
}
