import styled, { css } from '@xstyled/styled-components';

type SidenavWrapperProps = {
  $expanded: boolean;
};

export const Container = styled.nav<SidenavWrapperProps>`
  display: grid;
  block-size: 100%;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  grid-template-rows: max-content auto;
  inline-size: 272px;
  justify-items: center;
  min-inline-size: 272px;

  /*
   * we delay min-inline-size since we don't want to transition it
   */
  transition: inline-size ease 300ms, min-inline-size 300ms 0s;

  ${({ $expanded }) =>
    !$expanded &&
    css`
      inline-size: 86px;
      min-inline-size: 86px;
    `}
`;
