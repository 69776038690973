import { useCallback, useEffect, useRef } from 'react';

import { OauthCodeQuery, OauthCodeQueryVariables } from '@generated/OauthCodeQuery';
import { OauthCodeQueryV2, OauthCodeQueryV2Variables } from '@generated/OauthCodeQueryV2';
import { OAUTH_CODE_QUERY, OAUTH_CODE_QUERY_V2 } from '@graphql/queries/OauthCodeQuery';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useImperativeQuery } from '@hooks/useImperativeQuery/useImperativeQuery';
import { useQueryParams } from '@hooks/useQueryParams';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

export function useOauthLogin(): void {
  const hasSubmitted = useRef(false);
  const { t } = useTranslation();
  const { searchParams } = useQueryParams();

  const { enableAuthV2Endpoints } = useFeaturesFlags();

  const oauthCodeQuery = enableAuthV2Endpoints ? OAUTH_CODE_QUERY_V2 : OAUTH_CODE_QUERY;

  const query = useImperativeQuery<
    OauthCodeQuery | OauthCodeQueryV2,
    OauthCodeQueryVariables | OauthCodeQueryV2Variables
  >({
    query: oauthCodeQuery,
  });

  const toast = useToast();

  const onSubmit = useCallback(async () => {
    try {
      const clientId = searchParams.get('client_id') ?? '';
      const redirectUri = searchParams.get('redirect_uri') ?? '';
      const responseType = searchParams.get('response_type') ?? '';
      const state = searchParams.get('state') ?? '';

      const response = await query({
        clientId: `client_id=${clientId}`,
        responseType: `response_type=${responseType}`,
        redirectUri: `redirect_uri=${redirectUri}`,
        state: `state=${state}`,
      });
      if (!enableAuthV2Endpoints && 'oauthCode' in response.data!) {
        window.location.replace(response.data!.oauthCode.redirectTo);
      }
    } catch (err) {
      toast.showToast({
        variant: 'critical',
        message: t('generic_errors.other.unknown_error'),
      });
      setTimeout(() => {
        window.history.go(-1);
      }, 2000);
    }
  }, [enableAuthV2Endpoints, query, searchParams, t, toast]);

  useEffect(() => {
    if (!hasSubmitted.current) {
      hasSubmitted.current = true;
      onSubmit();
    }
  }, [onSubmit]);
}
