/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';

import { MetaEmbeddedSignupButtonProps } from './MetaEmbeddedSignupButton.decl';

import { Button, ButtonProps } from '@aircall/tractor-v2';
import {
  getEmbeddedSignupListener,
  launchEmbeddedSignup,
  loadFacebookSDKScript,
} from '@components/MetaEmbeddedSignupButton/helpers/metaEmbeddedSignup.helpers';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

const FacebookButton = styled(Button)<ButtonProps>`
  height: 40px;
  padding: 0 24px;
  border: 0;
  border-radius: 4px;
  background-color: #1877f2;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;

  :hover {
    background-color: #1877f2;
  }

  :disabled {
    background-color: #e0e0e0;
    color: #a0a0a0;
  }
`;

export function MetaEmbeddedSignupButton({
  onEmbeddedSignup,
}: Readonly<MetaEmbeddedSignupButtonProps>) {
  const { t } = useTranslation();
  const [enableFacebookButton, setEnableFacebookButton] = useState(false);

  const embeddedSignupListener = useCallback(
    () => getEmbeddedSignupListener(onEmbeddedSignup),
    [onEmbeddedSignup]
  );

  useEffect(() => {
    loadFacebookSDKScript()
      .then(() => {
        setEnableFacebookButton(true);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    window.addEventListener('message', embeddedSignupListener);
    return () => {
      window.removeEventListener('message', embeddedSignupListener);
    };
  }, [embeddedSignupListener]);

  return (
    <FacebookButton
      disabled={!enableFacebookButton}
      onClick={launchEmbeddedSignup}
      data-test='facebook-button'
    >
      {t('number_details.integrations.whatsapp_integration.facebook_button')}
    </FacebookButton>
  );
}
