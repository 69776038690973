import styled from 'styled-components';

import { MacFilled, WindowsFilled } from '@aircall/icons';
import { Button, Icon } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { osType } from '@dashboard/library/build/helpers/browser/browser.helpers.decl';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: left;
`;

const Icons = {
  mac: MacFilled,
  windows: WindowsFilled,
};

export function DownloadAppButton({
  os,
}: Readonly<{ os: osType | 'macIntel' | 'macPkg' | 'windowsExe' }>) {
  const { t } = useTranslation();

  // Roll out the new links for Aircall Workspace based on
  // the dashboard_onboarding_links_for_aw feature flag
  const { dashboardOnboardingLinksForAw } = useFeaturesFlags();
  const linkType = dashboardOnboardingLinksForAw ? 'application_aw' : 'application';

  let osIcon: osType = 'windows';
  if (!['windows', 'windowsExe'].includes(os)) {
    osIcon = 'mac';
  }

  return (
    <StyledLink
      href={t(`resellers.${ResellerConfig.identifier}.onboarding.${linkType}.download_link_${os}`)}
      target='_blank'
      rel='noreferrer noopener'
      data-test='download-app-button-link'
    >
      <StyledButton variant='secondary' mode='outline'>
        <Icon component={Icons[osIcon]} />
        {t(`onboarding.${linkType}.download_for_${os}`)}
      </StyledButton>
    </StyledLink>
  );
}
