/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-unresolved */

import { FunctionComponent } from 'react';

import * as RoutesConstants from '../../constants/routes.constants';
import { FeaturesFlags } from '../../hooks/useFeatures/useFeaturesFlags.decl';

import {
  ActivityOutlined,
  AnalyticsOutlined,
  AnalyticsPlusOutlined,
  BlockedOutlined,
  CalendarOutlined,
  CallOutlined,
  DiagonalDownOutlined,
  DiagonalUpOutlined,
  HeadsetOutlined,
  HistoryOutlined,
  Integrations1Outlined,
  PeopleOutlined,
  SoundwaveOutlined,
  TagsOutlined,
  UserOutlined,
  VisibleOnOutlined,
  VoicemailOutlined,
} from '@aircall/icons';
import { ReactComponent as PhoneArrowBounceSVG } from '@assets/icons/phone-arrow-bounce.svg';
import { ReactComponent as UsersIcon } from '@assets/icons/sidenav/users.svg';
import { RESOURCE } from '@constants/permissions.constants';
import { Company } from '@dashboard/extension';
import { checkIsLiveMonitoringAvailable } from '@helpers/liveMonitoring.helpers';
import { AdminFeatures } from '@hooks/useFeatures/useAdminFeatures.decl';

export type NavItemKey = string;
export type FeatureCheck =
  | (() => boolean | undefined)
  | ((features: FeaturesFlags & AdminFeatures) => boolean | undefined);
export type FeaturePlusCheck = (
  features: FeaturesFlags & AdminFeatures,
  company: Company
) => boolean;

export interface SidebarItem {
  key: string | number;
  to: string;
  icon: FunctionComponent;
  title: string;
  dataTest: string;
  position?: number;
  children?: SidebarItem[];
  resource?: RESOURCE;
  resources?: RESOURCE[];
  enabled: FeaturePlusCheck;
  display?: FeatureCheck;
  isBeta: FeatureCheck;
  isNew?: FeatureCheck;
  isAd?: boolean;
  hasSeparator?: FeatureCheck;
  groupedRoutes?: string[];

  /**
   * In case we want to hide the item but keep the route.
   * This is useful when we want to enable/disable an extension
   * that uses the same route as the old sidebar item.
   * If we use "enabled" instead of "hidden"
   * the route will be disabled in "ContentWithinNavigation".
   */
  hidden?: FeatureCheck;
}

/* For testing purposes */
export const yes = () => true;
const no = () => false;

/**
 * Config for the side bar items
 */
export const SIDEBAR_ITEMS_CONFIG: Record<NavItemKey, SidebarItem> = {
  [RoutesConstants.USERS_AND_TEAMS_GROUP]: {
    key: RoutesConstants.USERS_AND_TEAMS_GROUP,
    to: RoutesConstants.USERS_AND_TEAMS_GROUP,
    icon: UsersIcon,
    title: 'users-and-teams-group.title',
    dataTest: RoutesConstants.USERS_AND_TEAMS_GROUP,
    isBeta: no,
    enabled: yes,
    position: 2,
    children: [],
    hidden: (featuresFlags) => !featuresFlags.dashboardEnabledNewSidebar,
    groupedRoutes: [RoutesConstants.USERS_ROUTE, RoutesConstants.TEAMS_ROUTE],
  },
  [RoutesConstants.NUMBERS_ROUTE]: {
    key: 'users',
    to: RoutesConstants.NUMBERS_ROUTE,
    icon: CallOutlined,
    title: 'numbers.title',
    dataTest: 'numbers-nav-item',
    enabled: yes,
    isBeta: no,
    resource: RESOURCE.NUMBERS,
  },
  [RoutesConstants.TEAMS_ROUTE]: {
    key: 'teams',
    to: RoutesConstants.TEAMS_ROUTE,
    icon: PeopleOutlined,
    title: 'teams.title',
    dataTest: 'teams-nav-item',
    enabled: yes,
    hidden: (featuresFlags) => featuresFlags.dashboardExtensionEnableTeamExtension,
    isBeta: no,
    resource: RESOURCE.TEAMS,
  },
  [RoutesConstants.USERS_ROUTE]: {
    key: 'route',
    to: RoutesConstants.USERS_ROUTE,
    icon: UserOutlined,
    title: 'users.title',
    dataTest: 'users-nav-item',
    hidden: (featuresFlags) => featuresFlags.dashboardExtensionEnableUserExtension,
    enabled: yes,
    isBeta: no,
    resource: RESOURCE.USERS,
    isNew: ({ enablePermissionsNotifications }) => !!enablePermissionsNotifications,
  },
  [RoutesConstants.ACTIVITY_FEED_ROUTE]: {
    key: 'activity-feed',
    to: RoutesConstants.ACTIVITY_FEED_ROUTE,
    icon: ActivityOutlined,
    title: 'activity_feed.title',
    dataTest: 'activity-feed-nav-item',
    enabled: (featuresFlags, company) => {
      const { dashboardHideActivityfeed } = featuresFlags;
      const ffs = featuresFlags as unknown as Record<string, boolean>;
      const isLiveMonitoringAvailable = checkIsLiveMonitoringAvailable(ffs, company);

      // We display Activity Feed if it's not hidden OR if Live Monitoring is not available
      // because we don't want to end up with no activity and no live monitoring.
      return !dashboardHideActivityfeed || !isLiveMonitoringAvailable;
    },
    isBeta: no,
    resource: RESOURCE.ACTIVITY_FEED,
  },
  [RoutesConstants.STATS_ROUTE]: {
    key: 'stats',
    to: RoutesConstants.STATS_ROUTE,
    icon: AnalyticsOutlined,
    title: 'stats.title',
    dataTest: 'stats-nav-item',
    enabled: yes,
    isBeta: no,
    resource: RESOURCE.STATS,
    children: [
      // OVERVIEW
      {
        key: 'statsOverviewNew',
        to: RoutesConstants.STATS_CHILD_ROUTE.OVERVIEW_BETA,
        icon: VisibleOnOutlined,
        title: 'stats.tabs.overview',
        dataTest: 'stats-nav-overview-new',
        enabled: ({ dashboardNewOverviewEnabled }) => !dashboardNewOverviewEnabled, // depends on the new FF
        isBeta: yes,
      },

      // OVERVIEW
      {
        key: 'statsOverview',
        to: RoutesConstants.STATS_CHILD_ROUTE.OVERVIEW,
        icon: VisibleOnOutlined,
        title: 'stats.tabs.overview',
        dataTest: 'stats-nav-overview',
        enabled: yes,
        isBeta: no,
        hasSeparator: ({ showGoalOrientedForProOnAnalyticsPlusException }) =>
          showGoalOrientedForProOnAnalyticsPlusException,
      },

      // ANALYTICS PRO
      {
        key: 'statsAnalytics',
        to: RoutesConstants.STATS_CHILD_ROUTE.ANALYTICS,
        icon: AnalyticsOutlined,
        title: 'stats.tabs.analytics',
        dataTest: 'stats-nav-analytics',
        enabled: ({ showLegacyAnalytics, showAnalyticsPlus }) =>
          showLegacyAnalytics || !showAnalyticsPlus,
        isBeta: no,
      },

      // USER ACTIVITY
      {
        key: 'statsUsersActivityPro',
        to: RoutesConstants.STATS_CHILD_ROUTE.USERS_ACTIVITY_PRO,
        icon: HeadsetOutlined,
        title: 'stats.tabs.users_activity_pro',
        dataTest: 'stats-nav-users-activity-pro',
        enabled: ({
          isAdvancedAnalyticsAllowed,
          showAnalyticsPlus,
          showGoalOrientedForProOnAnalyticsPlusException,
          showUsersActivityDashboardPro,
        }) =>
          Boolean(
            showGoalOrientedForProOnAnalyticsPlusException ||
              (!showAnalyticsPlus && showUsersActivityDashboardPro && isAdvancedAnalyticsAllowed)
          ),
        isBeta: no,
      },

      // INBOUND
      {
        key: 'statsInbound',
        to: RoutesConstants.STATS_CHILD_ROUTE.INBOUND,
        icon: VisibleOnOutlined,
        title: 'stats.tabs.inbound',
        dataTest: 'stats-nav-inbound',
        enabled: ({ showInbound }) => Boolean(showInbound),
        isBeta: ({ showInbound }) => showInbound,
      },

      // INBOUND
      {
        key: 'statsInboundActivityPro',
        to: RoutesConstants.STATS_CHILD_ROUTE.INBOUND_ACTIVITY_PRO,
        icon: DiagonalDownOutlined,
        title: 'stats.tabs.inbound_activity_pro',
        dataTest: 'stats-nav-inbound-activity-pro',
        enabled: ({
          isAdvancedAnalyticsAllowed,
          showAnalyticsPlus,
          showGoalOrientedForProOnAnalyticsPlusException,
          showInboundActivityDashboardPro,
        }) =>
          Boolean(
            showGoalOrientedForProOnAnalyticsPlusException ||
              (!showAnalyticsPlus && showInboundActivityDashboardPro && isAdvancedAnalyticsAllowed)
          ),
        isBeta: no,
      },

      // OUTBOUND
      {
        key: 'statsOutboundActivityPro',
        to: RoutesConstants.STATS_CHILD_ROUTE.OUTBOUND_ACTIVITY_PRO,
        icon: DiagonalUpOutlined,
        title: 'stats.tabs.outbound_activity_pro',
        dataTest: 'stats-nav-outbound-activity-pro',
        enabled: ({
          isAdvancedAnalyticsAllowed,
          showAnalyticsPlus,
          showGoalOrientedForProOnAnalyticsPlusException,
          showOutboundActivityDashboardPro,
        }) =>
          Boolean(
            showGoalOrientedForProOnAnalyticsPlusException ||
              (!showAnalyticsPlus && showOutboundActivityDashboardPro && isAdvancedAnalyticsAllowed)
          ),
        isBeta: no,
      },

      // UNANSWERED CALLS
      {
        key: 'statsUnansweredCallsPro',
        to: RoutesConstants.STATS_CHILD_ROUTE.UNANSWERED_CALLS_PRO,
        icon: PhoneArrowBounceSVG,
        title: 'stats.tabs.unanswered_calls_pro',
        dataTest: 'stats-nav-unanswered-calls-pro',
        enabled: ({
          isAdvancedAnalyticsAllowed,
          showUnansweredCallsDashboardPro,
          showGoalOrientedForProOnAnalyticsPlusException,
          showAnalyticsPlus,
        }) =>
          Boolean(
            showGoalOrientedForProOnAnalyticsPlusException ||
              (!showAnalyticsPlus && showUnansweredCallsDashboardPro && isAdvancedAnalyticsAllowed)
          ),
        isBeta: no,
      },

      // NETWORK DIAGNOSTICS (formerly CALL DIAGNOSTICS/CALL QUALITY)
      {
        key: 'statsCallQualityPro',
        to: RoutesConstants.STATS_CHILD_ROUTE.CALL_QUALITY_PRO,
        icon: SoundwaveOutlined,
        title: 'stats.tabs.network_diagnostics_pro',
        dataTest: 'stats-nav-call-quality-pro',
        enabled: ({
          isAdvancedAnalyticsAllowed,
          showAnalyticsPlus,
          showCallQualityDashboardPro,
          showGoalOrientedForProOnAnalyticsPlusException,
        }) =>
          Boolean(
            showGoalOrientedForProOnAnalyticsPlusException ||
              (!showAnalyticsPlus && showCallQualityDashboardPro && isAdvancedAnalyticsAllowed)
          ),
        isBeta: no,
        hasSeparator: ({
          isAdvancedAnalyticsAllowed,
          showAnalyticsPlus,
          showGoalOrientedForProOnAnalyticsPlusException,
        }) =>
          showGoalOrientedForProOnAnalyticsPlusException ||
          (!showAnalyticsPlus && isAdvancedAnalyticsAllowed),
      },

      // ANALYTICS +
      {
        key: 'statsAnalyticsPlus',
        to: RoutesConstants.STATS_CHILD_ROUTE.ANALYTICS_PLUS,
        icon: AnalyticsPlusOutlined,
        title: 'stats.tabs.analytics_plus',
        dataTest: 'stats-nav-analytics-plus',
        enabled: ({ showAnalyticsPlus }) => showAnalyticsPlus,
        isBeta: no,
      },

      // USER ACTIVITY +
      {
        key: 'statsUsersActivity',
        to: RoutesConstants.STATS_CHILD_ROUTE.USERS_ACTIVITY,
        icon: HeadsetOutlined,
        title: 'stats.tabs.users_activity',
        dataTest: 'stats-nav-users-activity',
        enabled: ({ showAnalyticsPlus }) => showAnalyticsPlus,
        isBeta: no,
      },

      // INBOUND +
      {
        key: 'statsInboundActivity',
        to: RoutesConstants.STATS_CHILD_ROUTE.INBOUND_ACTIVITY,
        icon: DiagonalDownOutlined,
        title: 'stats.tabs.inbound_activity',
        dataTest: 'stats-nav-inbound-activity',
        enabled: ({ showAnalyticsPlus }) => showAnalyticsPlus,
        isBeta: no,
      },

      // OUTBOUND +
      {
        key: 'statsOutboundActivity',
        to: RoutesConstants.STATS_CHILD_ROUTE.OUTBOUND_ACTIVITY,
        icon: DiagonalUpOutlined,
        title: 'stats.tabs.outbound_activity',
        dataTest: 'stats-nav-outbound-activity',
        enabled: ({ showAnalyticsPlus }) => showAnalyticsPlus,
        isBeta: no,
      },

      // UNANSWERED CALLS +
      {
        key: 'statsUnansweredCalls',
        to: RoutesConstants.STATS_CHILD_ROUTE.UNANSWERED_CALLS,
        icon: PhoneArrowBounceSVG,
        title: 'stats.tabs.unanswered_calls',
        dataTest: 'stats-nav-unanswered-calls',
        enabled: ({ showAnalyticsPlus }) => showAnalyticsPlus,
        isBeta: no,
      },

      // MONITORING +
      {
        key: 'statsMonitoring',
        to: RoutesConstants.STATS_CHILD_ROUTE.MONITORING,
        icon: AnalyticsPlusOutlined,
        title: 'stats.tabs.monitoring',
        dataTest: 'stats-nav-monitoring',
        enabled: ({ showAnalyticsPlus }) => showAnalyticsPlus,
        isBeta: no,
      },

      // NETWORK DIAGNOSTICS + (formerly CALL DIAGNOSTICS +/CALL QUALITY +)
      {
        key: 'statsCallQuality',
        to: RoutesConstants.STATS_CHILD_ROUTE.CALL_QUALITY,
        icon: SoundwaveOutlined,
        title: 'stats.tabs.network_diagnostics',
        dataTest: 'stats-nav-call-quality',
        enabled: ({ showAnalyticsPlus, showCallQualityDashboard }) =>
          Boolean(showAnalyticsPlus && showCallQualityDashboard),
        isBeta: no,
        hasSeparator: ({ showAnalyticsPlus }) => showAnalyticsPlus,
      },

      // USER STATUS HISTORY +
      {
        key: 'statsAvailabilities',
        to: RoutesConstants.STATS_CHILD_ROUTE.AVAILABILITIES,
        icon: HistoryOutlined,
        title: 'stats.tabs.availabilities',
        dataTest: 'stats-nav-availabilities',
        enabled: ({ analyticsAvailabilitiesDashboard, showAnalyticsPlus }) =>
          Boolean(analyticsAvailabilitiesDashboard && showAnalyticsPlus),
        isBeta: no,
      },

      // CALL HISTORY
      {
        key: 'callHistory',
        to: RoutesConstants.STATS_CHILD_ROUTE.CALL_HISTORY,
        icon: HistoryOutlined,
        title: 'calls.pages.call_history.title',
        dataTest: 'calls-nav-call-history',
        enabled: yes,
        isBeta: no,
        hasSeparator: ({ showAnalyticsPlus, showAnalyticsplusTeaserMenu }) =>
          !showAnalyticsPlus && showAnalyticsplusTeaserMenu,
      },

      // ANALYTICS +
      {
        key: 'statsAnalyticsPlusAd',
        to: RoutesConstants.STATS_CHILD_ROUTE.ANALYTICS_PLUS,
        icon: AnalyticsPlusOutlined,
        title: 'stats.tabs.analytics_plus',
        dataTest: 'stats-nav-analytics-plus-ad',
        enabled: ({ showAnalyticsPlus, showAnalyticsplusTeaserMenu }) =>
          Boolean(!showAnalyticsPlus && showAnalyticsplusTeaserMenu),
        isBeta: no,
      },
    ],
  },
  [RoutesConstants.CALLS_ROUTE]: {
    key: 6,
    to: RoutesConstants.CALLS_ROUTE,
    icon: HistoryOutlined,
    title: 'calls.title',
    dataTest: 'calls-nav-item',
    enabled: yes,
    isBeta: no,
    resource: RESOURCE.CALL_SETTINGS,
    children: [
      {
        key: 'callAsset',
        to: RoutesConstants.CALLS_CHILD_ROUTE.CALL_ASSET,
        icon: VoicemailOutlined,
        title: 'calls.pages.call_asset.title',
        dataTest: 'calls-nav-call-asset',
        enabled: yes,
        display: no,
        isBeta: no,
      },
      {
        key: 'calendarMgmt',
        to: RoutesConstants.CALLS_CHILD_ROUTE.CALENDARS,
        icon: CalendarOutlined,
        title: 'calls.pages.calendar_management.title',
        dataTest: 'calls-nav-date-exceptions',
        enabled: ({ dashboardExtensionEnableCalendarMgmtExtension }) =>
          Boolean(dashboardExtensionEnableCalendarMgmtExtension),
        display: yes,
        isBeta: no,
        isNew: ({ dashboardExtensionEnableCalendarMgmtExtension }) =>
          dashboardExtensionEnableCalendarMgmtExtension,
        resource: RESOURCE.NUMBERS,
      },
      {
        key: 'callTags',
        to: RoutesConstants.CALLS_CHILD_ROUTE.TAGS,
        icon: TagsOutlined,
        title: 'calls.pages.tags.title',
        dataTest: 'calls-nav-tags',
        enabled: yes,
        isBeta: no,
      },
      {
        key: 'blockedNumbers',
        to: RoutesConstants.CALLS_CHILD_ROUTE.BLOCKED_NUMBERS,
        icon: BlockedOutlined,
        title: 'calls.pages.blocked_numbers.title',
        dataTest: 'calls-nav-bocked-numbers',
        enabled: yes,
        isBeta: no,
      },
    ],
  },
  [RoutesConstants.INTEGRATIONS_ROUTE]: {
    key: 7,
    to: RoutesConstants.INTEGRATIONS_CHILD_ROUTE.CONNECTED_INTEGRATIONS,
    icon: Integrations1Outlined,
    title: 'integrations.title',
    dataTest: 'integrations-nav-item',
    enabled: yes,
    isBeta: no,
    resource: RESOURCE.INTEGRATIONS,
  },
  // Route used for testing purposes: disabled top-level route
  [RoutesConstants.DISABLED_TOP_LEVEL_TEST_ROUTE]: {
    key: 'test',
    to: RoutesConstants.DISABLED_TOP_LEVEL_TEST_ROUTE,
    icon: AnalyticsOutlined,
    title: 'test',
    dataTest: 'disable-top-level-test-nav-item',
    enabled: no,
    display: no,
    isBeta: no,
    resource: undefined,
  },
};
