import { setResponseWithHeaders } from './setResponseWithHeaders';

import { RestLink } from 'apollo-link-rest';

export async function customFetch(uri: RequestInfo, config: RequestInit): Promise<Response> {
  const res = await fetch(uri, config);
  let data;

  try {
    data = await res.clone().json();
  } catch (e) {
    data = await res.clone().text();
  }

  if (res.status >= 400) {
    const error = new Error('Response not successful') as RestLink.ServerError;
    error.response = res;
    error.statusCode = res.status;
    // For 401 we do not keep the data otherwise apollo can interpret it as a graphql error and calls errorLink twice
    error.result = res.status === 401 ? {} : data;
    throw error;
  }
  if (res.status === 200) {
    /**
     * Edge case where api returns an empty response body for a 200 without 'Content-Length' header being set
     * we have to ensure this header is set as '0' so apollo is able to parse json properly
     * see https://github.com/apollographql/apollo-link-rest/issues/107
     */
    if (!data) {
      return setResponseWithHeaders(res, (headers) => {
        headers.set('Content-Length', '0');
        return headers;
      });
    }
  }
  if (res.status === 302) {
    const location = res.headers.get('Location');
    if (location) {
      return fetch(location, config);
    }
  }
  return res;
}
