import { Company } from '@dashboard/extension';
import { checkIsLiveMonitoringEnabled, checkIsLiveMonitoringPlusEnabled } from '@dashboard/library';

export function checkIsLiveMonitoringAvailable(
  featureFlags: Record<string, boolean>,
  company: Company
): boolean {
  const isLiveMonitoringExtensionEnabled =
    featureFlags.dashboardExtensionEnableLiveMonitoringExtension;
  const isLiveMonitoringEnabled = checkIsLiveMonitoringEnabled(featureFlags, company);
  const isLiveMonitoringPlusEnabled = checkIsLiveMonitoringPlusEnabled(featureFlags);

  return (
    isLiveMonitoringExtensionEnabled && (isLiveMonitoringEnabled || isLiveMonitoringPlusEnabled)
  );
}
