import { gql } from '@apollo/client';

export const OAUTH_CODE_QUERY = gql`
  query OauthCodeQuery(
    $clientId: String!
    $redirectUri: String!
    $responseType: String!
    $state: String!
  ) {
    oauthCode(
      clientId: $clientId
      redirectUri: $redirectUri
      responseType: $responseType
      state: $state
    )
      @rest(
        type: "OauthCodeQueryResponse"
        path: "auth/v1/oauth/code?{args.clientId}&{args.redirectUri}&{args.responseType}&{args.state}"
        method: "GET"
        endpoint: "authentication"
      ) {
      redirectTo
    }
  }
`;

export const OAUTH_CODE_QUERY_V2 = gql`
  query OauthCodeQueryV2(
    $clientId: String!
    $redirectUri: String!
    $responseType: String!
    $state: String!
  ) {
    oauthCodeV2(
      clientId: $clientId
      redirectUri: $redirectUri
      responseType: $responseType
      state: $state
    )
      @rest(
        type: "OauthCodeQueryV2Response"
        path: "auth/v2/oauth/code?{args.clientId}&{args.redirectUri}&{args.responseType}&{args.state}"
        method: "POST"
        endpoint: "authentication"
      ) {
      _
    }
  }
`;
