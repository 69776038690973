import { Box, Flex } from '@aircall/tractor-v2';
import { INLINE_END_OUTLINE_OFFSET } from '@components/Sidenav/Sidenav.utils';
import styled, { css } from '@xstyled/styled-components';

export const AircallIconContainer = styled(Box)``;

type StyledFooterProps = {
  $expanded: boolean;
};

export const Footer = styled(Flex)<StyledFooterProps>`
  position: relative;
  justify-content: center;

  ${AircallIconContainer} {
    position: absolute;
    opacity: 0;
    transition: opacity 0s;
  }

  @supports (scrollbar-gutter: stable) {
    padding-inline-end: ${INLINE_END_OUTLINE_OFFSET * 2}px;
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      ${AircallIconContainer} {
        position: relative;
        opacity: 1;
        transition: opacity 0.3s 0.3s ease;
      }
    `}
`;
