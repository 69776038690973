/* eslint-disable @typescript-eslint/naming-convention */
import { Permissions } from '../components/Permissions';

import { ResellerConfig } from '@config/resellers.config';
import aircallConfig from '@config/resellers/aircall';
import { COOKIE_KEYS } from '@constants/auth.constants';
import { IS_DEVELOPMENT, OLD_COOKIE_DOMAIN } from '@constants/environment.constants';
import { PERMISSIONS_ACTIONS } from '@constants/permissions.constants';
import { isTruthy } from '@dashboard/library';
import {
  DecodedToken,
  ParsedDecodedToken,
  SAME_SITE_TYPE,
  UpdateCognitoTokenParams,
} from '@state/app/authentication/authentication.decl';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

/**
 * Sets a cookie with given parameters.
 * @param name - key of the cookie
 * @param value - value of the cookie
 * @param domain - domain of the cookie
 * @param path - path of the cookie
 */
export function setCookie<T extends string>(
  name: string,
  value: T,
  domain = ResellerConfig.cookieDomain,
  sameSite = SAME_SITE_TYPE.NONE,
  path = '/'
): void {
  Cookies.set(name, value, {
    domain,
    path,
    ...(!IS_DEVELOPMENT && { sameSite, secure: true }),
  });
}

/**
 * Removes a cookie based on its name.
 * @param name - name of the cookie
 */
export function removeCookie(
  name: string,
  sameSite = SAME_SITE_TYPE.NONE,
  domain = ResellerConfig.cookieDomain
): void {
  // We make sure we remove old cookies as well
  //
  Cookies.remove(name);
  Cookies.remove(name, { domain });
  Cookies.remove(name, { domain, path: '/' });

  if (!IS_DEVELOPMENT) {
    Cookies.remove(name, {
      domain,
      path: '/',
      sameSite,
      secure: true,
    });
  }
}

/**
 * Sets token and refresh token with given values.
 * @param updateCognitoTokenParams - destructured parameters
 */
export function updateToken({ refreshToken, idToken }: UpdateCognitoTokenParams): void {
  if (IS_DEVELOPMENT) {
    setCookie(COOKIE_KEYS.AC_TOKEN, idToken);
    setCookie(COOKIE_KEYS.AC_REFRESH_TOKEN, refreshToken);
  }
  setCookie(COOKIE_KEYS.TOKEN, idToken);
  setCookie(COOKIE_KEYS.REFRESH_TOKEN, refreshToken);
}

/**
 * Clears auth cookies used in the app.
 */
export function clearCookies(): void {
  const { backendIssuedCookieDomain } = ResellerConfig;
  const backendIssuedCookieSameSite = SAME_SITE_TYPE.LAX;
  removeCookie(COOKIE_KEYS.AC_TOKEN, backendIssuedCookieSameSite, backendIssuedCookieDomain);
  removeCookie(
    COOKIE_KEYS.AC_REFRESH_TOKEN,
    backendIssuedCookieSameSite,
    backendIssuedCookieDomain
  );
  removeCookie(COOKIE_KEYS.TOKEN);
  removeCookie(COOKIE_KEYS.REFRESH_TOKEN);
  removeCookie(COOKIE_KEYS.IS_CONNECTED_AS);
  removeCookie(COOKIE_KEYS.LEGACY_TOKEN);
  removeCookie(COOKIE_KEYS.AUTH_STRATEGY);
  removeCookie(COOKIE_KEYS.SAML_LOGOUT_URL);
  removeCookie(COOKIE_KEYS.IMPERSONATE_TOKEN);
  removeCookie(COOKIE_KEYS.IMPERSONATE_TOKEN, undefined, backendIssuedCookieDomain);
  removeCookie(COOKIE_KEYS.HIDE_INTEGRATION_UPDATE_MODAL);
}

/**
 * Sets auth cookies to impersonate a user.
 * @param impersonateToken - token of the impersonated user
 */
export function setConnectedAsCookies(impersonateToken: string): void {
  setCookie(COOKIE_KEYS.IS_CONNECTED_AS, 'true');
  setCookie(COOKIE_KEYS.TOKEN, impersonateToken);
  setCookie(
    COOKIE_KEYS.AC_TOKEN,
    impersonateToken,
    ResellerConfig.backendIssuedCookieDomain,
    SAME_SITE_TYPE.LAX
  );
  removeCookie(COOKIE_KEYS.IMPERSONATE_TOKEN);
}

export function removeOldCookie(): void {
  /* istanbul ignore else */
  if (ResellerConfig.appName === aircallConfig.appName && OLD_COOKIE_DOMAIN) {
    const token = Cookies.get(COOKIE_KEYS.TOKEN);
    const refreshToken = Cookies.get(COOKIE_KEYS.REFRESH_TOKEN);
    /* istanbul ignore else */
    if (token && refreshToken) {
      setCookie(COOKIE_KEYS.TOKEN, token);
      setCookie(COOKIE_KEYS.REFRESH_TOKEN, refreshToken);
    }

    Cookies.remove(COOKIE_KEYS.TOKEN, { domain: OLD_COOKIE_DOMAIN });
    Cookies.remove(COOKIE_KEYS.TOKEN, { domain: OLD_COOKIE_DOMAIN, path: '/' });
    Cookies.remove(COOKIE_KEYS.REFRESH_TOKEN, { domain: OLD_COOKIE_DOMAIN });
    Cookies.remove(COOKIE_KEYS.REFRESH_TOKEN, { domain: OLD_COOKIE_DOMAIN, path: '/' });
  }
}

// We format the old enum to the new one ("create" => "c")
export function mapActions(action: PERMISSIONS_ACTIONS): PERMISSIONS_ACTIONS | null {
  const char = action.charAt(0) as PERMISSIONS_ACTIONS | undefined;

  if (!char || !Object.values(PERMISSIONS_ACTIONS).includes(char)) {
    return null;
  }

  return char;
}

export function parseToken(decodedToken: DecodedToken): ParsedDecodedToken {
  const permissionsKey = 'custom:permissions';
  const customPermissionsJson = decodedToken[permissionsKey] || '{}';

  let customPermissions: Permissions = {};

  try {
    const customPermissionsData: Permissions = JSON.parse(customPermissionsJson);

    customPermissions = Object.entries(customPermissionsData).reduce((acc, [resource, actions]) => {
      const mappedActions = actions.map(mapActions).filter(isTruthy);
      return { ...acc, [resource]: mappedActions };
    }, {});
  } catch (e) {
    // custom permissions stays empty
  }

  return {
    ...decodedToken,
    [permissionsKey]: customPermissions,
  };
}

/**
 * @param t - JWT token
 * @returns Object, decoded token
 */
export function decodeToken(t: string): ParsedDecodedToken {
  const decodedToken: DecodedToken = jwt_decode(t);
  const parsedToken = parseToken(decodedToken);
  return parsedToken;
}
