import React, { useEffect } from 'react';

import { Flex, ThemeProvider, Typography, Button, getSpace } from '@aircall/tractor-v2';
import { ReactComponent as HubspotIcon } from '@assets/icons/hubspot-icon.svg';
import { ReactComponent as IntercomIcon } from '@assets/icons/intercom-icon.svg';
import { ReactComponent as MondayIcon } from '@assets/icons/monday-icon.svg';
import { ReactComponent as SalesforceIcon } from '@assets/icons/salesforce-icon.svg';
import { ReactComponent as SlackIcon } from '@assets/icons/slack-icon.svg';
import { ReactComponent as ZendeskIcon } from '@assets/icons/zendesk-icon.svg';
import { INTEGRATIONS_CHILD_ROUTE } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import { StepStatus, StepType } from '@generated/globalTypes';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useOnboardingData } from '@hooks/useOnboardingData';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Row = styled(Flex)`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
`;

const FullRow = styled(Row)`
  justify-content: space-between;
`;

const IconContainer = styled(Flex)<{ borderStyle?: string; opacity?: number }>`
  display: flex;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border: 1px solid #a0b0c8;
  border-style: ${({ borderStyle = 'solid' }) => borderStyle};
  border-radius: 50%;
  opacity: ${({ opacity = 0.4 }) => opacity};
`;

const GradientLayout = styled(Flex)`
  background: linear-gradient(60deg, rgb(252 187 38 / 0.2), transparent 30%),
    linear-gradient(to left top, rgb(0 179 136 / 0.2), transparent 25%);
  overflow-y: auto;
`;

const FindProviderButton = styled(Button)`
  width: 400px;
  margin-top: ${getSpace('xl')};
`;

const icons = [
  // Row 1 (8 columns)
  { key: 'row1-1', icon: null, opacity: 0.2 },
  { key: 'row1-2', icon: null, opacity: 0.2 },
  { key: 'row1-3', icon: null },
  { key: 'row1-4', icon: null },
  { key: 'row1-5', icon: null },
  { key: 'row1-6', icon: null },
  { key: 'row1-7', icon: null, opacity: 0.2 },
  { key: 'row1-8', icon: null, opacity: 0.2 },
  // Row 2 (7 columns centered)
  { key: 'row2-1', icon: null, opacity: 0.2 },
  { key: 'row2-2', icon: null },
  { key: 'row2-3', icon: <SlackIcon /> },
  { key: 'row2-4', icon: <ZendeskIcon /> },
  { key: 'row2-5', icon: <IntercomIcon /> },
  { key: 'row2-6', icon: null },
  { key: 'row2-7', icon: null, opacity: 0.2 },
  // Row 3 (8 columns)
  { key: 'row3-1', icon: null, opacity: 0.2 },
  { key: 'row3-2', icon: null },
  { key: 'row3-3', icon: <SalesforceIcon /> },
  { key: 'row3-4', icon: <MondayIcon /> },
  { key: 'row3-5', icon: <HubspotIcon /> },
  {
    key: 'row3-6',
    icon: (
      <IconContainer key='icon-container' borderStyle='dashed' opacity={1}>
        <Typography variant='bodySemiboldM' textAlign='center' color='text-highlight'>
          +100
        </Typography>
      </IconContainer>
    ),
  },
  { key: 'row3-7', icon: null },
  { key: 'row3-8', icon: null, opacity: 0.2 },
  // Row 4 (7 columns centered)
  { key: 'row4-1', icon: null, opacity: 0.2 },
  { key: 'row4-2', icon: null },
  { key: 'row4-3', icon: null },
  { key: 'row4-4', icon: null },
  { key: 'row4-5', icon: null },
  { key: 'row4-6', icon: null },
  { key: 'row4-7', icon: null, opacity: 0.2 },
];

const ROW_SIZES = [8, 7, 8, 7];

function GenericIntegration() {
  const { showOnboardingTrial } = useFeaturesFlags();
  const navigate = useNavigate();
  const { updateOnboardingStepStatus } = useOnboardingData();
  const { t } = useTranslation();
  let iconIndex = 0;

  const handleFindProvider = () => {
    updateOnboardingStepStatus(StepType.TEST_INTEGRATION, StepStatus.COMPLETED);
    navigate(INTEGRATIONS_CHILD_ROUTE.APPLICATIONS);
  };

  useEffect(() => {
    if (!showOnboardingTrial) {
      navigate('/', { replace: true });
    }
  }, [navigate, showOnboardingTrial]);

  if (!showOnboardingTrial) {
    return <Loading data-test='generic-page-loader' />;
  }

  return (
    <ThemeProvider>
      <GradientLayout w='100%' h='100%' p='m' data-test='landing-page-wrapper'>
        <Flex
          position='relative'
          maxW='730px'
          m='auto'
          data-test='content-wrapper'
          flexDirection='column'
          alignItems='center'
        >
          <Flex flexDirection='column' alignItems='center' mt='40px' gap='xxs'>
            {ROW_SIZES.map((size) => {
              const RowComponent = size === 8 ? FullRow : Row;
              const rowIcons = icons.slice(iconIndex, iconIndex + size);
              iconIndex += size;
              return (
                <RowComponent key={`row-${size}`}>
                  {rowIcons.map((item) =>
                    item.icon === null ? (
                      <IconContainer key={item.key} opacity={item.opacity}>
                        {item.icon}
                      </IconContainer>
                    ) : (
                      React.cloneElement(item.icon, {
                        key: item.key,
                      })
                    )
                  )}
                </RowComponent>
              );
            })}
          </Flex>
          <Typography variant='headingBoldM' w='500px' textAlign='center' mt='40px'>
            {t('integration_flow.generic_integration.main_text')}
          </Typography>
          <Typography variant='headingBoldS' color='text-secondary' mt='10px'>
            {t('integration_flow.generic_integration.secondary_text')}
          </Typography>
          <FindProviderButton onClick={handleFindProvider}>
            {t('integration_flow.generic_integration.button_text')}
          </FindProviderButton>
        </Flex>
      </GradientLayout>
    </ThemeProvider>
  );
}

export default GenericIntegration;
