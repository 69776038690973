import { useMemo } from 'react';

import {
  DEFAULT_DAILY_CALL_LIMIT_MINUTES,
  FeaturesFlags,
  FeaturesFlagsData,
  ONBOARDING_TRIAL_DAILY_CALL_LIMIT_MINUTES,
} from './useFeaturesFlags.decl';

import { ResellerConfig } from '@config/resellers.config';
import { useGraphQuery } from '@dashboard/library';
import { FeaturesFlagsQuery } from '@generated/FeaturesFlagsQuery';
import { FEATURES_FLAGS_QUERY } from '@graphql/queries/FeaturesFlagsQuery';
import { useAuthenticationState } from '@hooks/useAuthenticationState';

/**
 * Hook providing feature flags.
 * @returns an object holding application feature flags
 */
export function useFeaturesFlags(): FeaturesFlags {
  const {
    authState: { isCognitoMigrated },
  } = useAuthenticationState();

  const { data, loading } = useGraphQuery<FeaturesFlagsQuery>(FEATURES_FLAGS_QUERY, {
    throwError: true,
  });

  const featureFlags = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const {
      featureFlags: { __typename, ...rest },
    } = data;

    return Object.entries(rest).reduce(
      (acc, [ffKey, ffValue]) => ({
        ...acc,
        [ffKey]: Boolean(ffValue),
      }),
      {} as FeaturesFlagsData
    );
  }, [data]);

  const {
    featureSet: { shouldShowReferAFriendButton, enableSaml: resellerEnableSaml },
  } = ResellerConfig;

  const enableSaml =
    isCognitoMigrated && Boolean(featureFlags?.useSamlAuthentication) && resellerEnableSaml;
  const dashboardSmartflowsEnabled =
    Boolean(featureFlags?.dashboardSmartflowsEnabled) && Boolean(featureFlags?.useSmartflows);
  const smartflowsEnabled = Boolean(featureFlags?.useSmartflows);
  const smartflowMigrationEnabled =
    smartflowsEnabled && Boolean(featureFlags?.dashboardEnableSmartflowsMigration);
  const showAnalyticsPlus = Boolean(featureFlags?.showLookerInAnalytics);

  const dailyCallLimitMinutes = featureFlags?.showOnboardingTrial
    ? ONBOARDING_TRIAL_DAILY_CALL_LIMIT_MINUTES
    : DEFAULT_DAILY_CALL_LIMIT_MINUTES;

  return useMemo(
    () => ({
      loading,
      ...(featureFlags ?? {}),
      dailyCallLimitInMinutes: dailyCallLimitMinutes,
      showReferAFriendButton: shouldShowReferAFriendButton,
      dashboardSmartflowsEnabled,
      smartflowsEnabled,
      smartflowMigrationEnabled,
      enableSaml,
      showAnalyticsPlus,
    }),
    [
      dailyCallLimitMinutes,
      dashboardSmartflowsEnabled,
      enableSaml,
      featureFlags,
      loading,
      shouldShowReferAFriendButton,
      showAnalyticsPlus,
      smartflowMigrationEnabled,
      smartflowsEnabled,
    ]
  );
}
