import { useCallback, useLayoutEffect } from 'react';

import {
  IRONCLAD_CONTRACT_EVENTS,
  UseContractCheckAndSignReturnType,
} from './useContractCheckAndSign.decl';

import { logger } from '@config/logger.config';
import { ASSETS_PAGE_ROUTE } from '@constants/regex.constants';
import { getDaysDifference } from '@dashboard/library';
import { GetSignupInformation } from '@generated/GetSignupInformation';
import { SIGNUP_INFORMATION_QUERY } from '@graphql/queries/SignupInformationQuery';
import {
  hasUserSignedLatestContracts,
  sendAcceptance,
  setSignerCustomData,
} from '@helpers/ironclad.helpers';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { usePathnameMatch } from '@hooks/usePathnameMatch';
import { useGraphQuery } from '@hooks/useQuery';
import { useQueryLocalisedContracts } from '@hooks/useQueryLocalisedContracts';
import { useTracker } from '@hooks/useTracker';
import { useNavigate } from 'react-router-dom';

export function useContractCheckAndSign(): UseContractCheckAndSignReturnType {
  const navigate = useNavigate();
  const { showOnboardingTrial, enableStarterPlan, dashboardStarterEnforceTos } = useFeaturesFlags();
  const skip = usePathnameMatch([ASSETS_PAGE_ROUTE]);
  const { data } = useGraphQuery<GetSignupInformation>(SIGNUP_INFORMATION_QUERY, {
    throwError: false,
    skip,
  });

  const {
    data: { contractIds, versionIds },
  } = useQueryLocalisedContracts(data?.getSignupInformation.country, skip);
  const { track } = useTracker();

  const { currentCompany, isTrial, currentUser } = useGlobalData();
  const now = new Date();
  const trialEndDate = new Date(isTrial ? currentCompany.trial!.trialEnd : now);
  const daysLeftInTrial = getDaysDifference(trialEndDate, now);

  const {
    authState: { isConnectedAs },
  } = useAuthenticationState();

  const checkAndSendAcceptance = useCallback(async () => {
    if (!contractIds || !versionIds || isConnectedAs) {
      return;
    }

    const { id: companyId, name: companyName } = currentCompany;
    const { email, firstName, lastName, ID: userId } = currentUser;

    try {
      await setSignerCustomData({
        companyId,
        companyName,
        email,
        firstName,
        lastName,
        userId,
      });
      const hasSignedLatest = await hasUserSignedLatestContracts(contractIds, currentCompany.id);
      if (!hasSignedLatest) {
        await sendAcceptance(currentCompany.id, versionIds, contractIds);
        logger.info('Sent Ironclad acceptance', { userId });
        track({
          event: IRONCLAD_CONTRACT_EVENTS.ACCEPTANCE_SENT,
          payload: {
            userId,
            companyId,
          },
        });
      }
    } catch (error) {
      logger.error(`Error checking or sending Ironclad acceptance: ${(error as Error).message}`);
      track({
        event: IRONCLAD_CONTRACT_EVENTS.ACCEPTANCE_FAILED,
        payload: {
          userId,
          companyId,
          error: (error as Error).message,
        },
      });
    }
  }, [contractIds, versionIds, isConnectedAs, currentCompany, currentUser, track]);

  useLayoutEffect(() => {
    if ((showOnboardingTrial || enableStarterPlan) && !dashboardStarterEnforceTos) {
      checkAndSendAcceptance();
    }
  }, [
    checkAndSendAcceptance,
    navigate,
    showOnboardingTrial,
    enableStarterPlan,
    dashboardStarterEnforceTos,
    currentUser.ID,
  ]);

  return { daysLeftInTrial };
}
