import { useCallback, useEffect, useMemo } from 'react';

import {
  OnboardingDataWithMappedSteps,
  OnboardingMappedAndFilteredStepType,
  UseOnboardingDataReturnType,
} from './useOnboardingData.decl';

import { TrialChecklistPopupItemType } from '@components/TrialChecklistPopup';
import { checklistDataV2 } from '@components/TrialChecklistPopup/TrialChecklistData';
import { logger } from '@config/logger.config';
import { WORKSPACE_URL } from '@constants/urls.constants';
import { useGraphMutation, useGraphQuery } from '@dashboard/library';
import { CreateOnboardingMutation } from '@generated/CreateOnboardingMutation';
import {
  GetOnboardingQuery,
  GetOnboardingQuery_getOnboarding_Onboarding_metadata,
  GetOnboardingQuery_getOnboarding_Onboarding_steps,
} from '@generated/GetOnboardingQuery';
import { MetadataTypeBoolean, StepStatus, StepType } from '@generated/globalTypes';
import { SearchTotalQuery } from '@generated/SearchTotalQuery';
import { UpdateOnboardingStepMutation } from '@generated/UpdateOnboardingStepMutation';
import { CREATE_ONBOARDING_MUTATION } from '@graphql/mutations/CreateOnboardingMutation';
import { UPDATE_ONBOARDING_METADATA_MUTATION } from '@graphql/mutations/UpdateOnboardingMetadataMutation';
import { UPDATE_ONBOARDING_STEP_MUTATION } from '@graphql/mutations/UpdateOnboardingStepMutation';
import { GET_ONBOARDING_QUERY } from '@graphql/queries/GetOnboardingQuery';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';

export const useOnboardingData = (): UseOnboardingDataReturnType => {
  const { showOnboardingTrial } = useFeaturesFlags();

  const { data, loading, refetch } = useGraphQuery<GetOnboardingQuery>(GET_ONBOARDING_QUERY, {
    skip: !showOnboardingTrial,
  });

  const getOnboardingResponse = data?.getOnboarding;

  const memoizedOnboardingFields = useMemo(() => {
    if (getOnboardingResponse?.__typename !== 'Onboarding' || !('steps' in getOnboardingResponse)) {
      return {
        onboardingData: null,
        noOnboardingData: true,
        phoneUrl: WORKSPACE_URL,
        allStepsCompleted: false,
        confettiShown: false,
        completedSteps: 0,
        totalSteps: 0,
      };
    }

    const filteredOnboardingSteps = checklistDataV2.reduce<OnboardingMappedAndFilteredStepType[]>(
      (
        acc: OnboardingMappedAndFilteredStepType[],
        currentStep: TrialChecklistPopupItemType
      ): OnboardingMappedAndFilteredStepType[] => {
        const hasOnboardingStep = getOnboardingResponse.steps.find(
          (step: GetOnboardingQuery_getOnboarding_Onboarding_steps) =>
            step.stepType === currentStep.stepType
        );

        if (hasOnboardingStep) {
          return [...acc, { ...hasOnboardingStep, ...currentStep }];
        }

        return acc;
      },
      []
    );

    const onboardingData = {
      ...getOnboardingResponse,
      steps: filteredOnboardingSteps,
    };

    const phoneStep = filteredOnboardingSteps.find(
      (step) => step.stepType === StepType.LAUNCH_PHONE
    );
    const phoneUrl = phoneStep?.redirectUrl ?? WORKSPACE_URL;

    const totalSteps = filteredOnboardingSteps.length;
    const completedSteps = filteredOnboardingSteps.filter(
      (step) => step.stepStatus === 'COMPLETED'
    ).length;
    const allStepsCompleted = totalSteps > 0 && completedSteps > 0 && totalSteps === completedSteps;

    const confettiShown = !!getOnboardingResponse.metadata.find(
      (meta: GetOnboardingQuery_getOnboarding_Onboarding_metadata) =>
        meta.key === MetadataTypeBoolean.SHOWN_CONFETTI_ON_DASHBOARD && meta.value
    );

    return {
      onboardingData,
      noOnboardingData: false,
      phoneUrl,
      allStepsCompleted,
      confettiShown,
      completedSteps,
      totalSteps,
    };
  }, [getOnboardingResponse]);

  const {
    onboardingData,
    noOnboardingData,
    completedSteps,
    totalSteps,
    allStepsCompleted,
    confettiShown,
    phoneUrl,
  } = memoizedOnboardingFields;

  const { data: totals, loading: totalsLoading } = useGraphQuery<SearchTotalQuery>(
    SEARCH_TOTAL_QUERY,
    {
      skip: !showOnboardingTrial,
    }
  );

  const [createOnboarding, { loading: createOnboardingLoading }] =
    useGraphMutation<CreateOnboardingMutation>(CREATE_ONBOARDING_MUTATION);
  const [updateOnboardingStep, { loading: updateStepLoading }] =
    useGraphMutation<UpdateOnboardingStepMutation>(UPDATE_ONBOARDING_STEP_MUTATION);
  const [updateOnboardingMetadata] = useGraphMutation<UpdateOnboardingStepMutation>(
    UPDATE_ONBOARDING_METADATA_MUTATION
  );

  const updateOnboardingStepStatus = useCallback(
    async (stepType: StepType, stepStatus: StepStatus) => {
      const { data: updatedOnboardingData, error } = await updateOnboardingStep({
        variables: {
          stepType,
          stepStatus,
        },
      });
      if (error) {
        logger.error('Failed to update onboarding step', { error });
        return;
      }
      if (updatedOnboardingData?.updateOnboardingStep?.__typename !== 'Onboarding') {
        logger.error('Failed to update onboarding step', { error });
      }
    },
    [updateOnboardingStep]
  );

  const createOnboardingData = useCallback(async () => {
    if (!showOnboardingTrial) {
      return;
    }
    logger.info('Creating onboarding data');
    // Check if the user has no onboarding data
    if (
      getOnboardingResponse?.__typename === 'GenericException' &&
      getOnboardingResponse.code === '0001'
    ) {
      const { error } = await createOnboarding();
      if (error) {
        logger.error('Failed to create onboarding', { error });
        return;
      }
      logger.info('Onboarding data created');
      await refetch();
    }
  }, [showOnboardingTrial, createOnboarding, refetch, getOnboardingResponse]);

  const updateConfettiShown = useCallback(
    async (value = true) => {
      try {
        const res = await updateOnboardingMetadata({
          variables: {
            key: MetadataTypeBoolean.SHOWN_CONFETTI_ON_DASHBOARD,
            value,
          },
        });
        if (res.error) {
          throw res.error;
        }
      } catch (error) {
        logger.error('Failed to update metadata', { error });
      }
    },
    [updateOnboardingMetadata]
  );

  const shouldMarkUserStepAsComplete = (
    onboardingDataInside: OnboardingDataWithMappedSteps | null,
    totalData: SearchTotalQuery
  ) =>
    onboardingDataInside &&
    onboardingDataInside.steps.find(
      (step: GetOnboardingQuery_getOnboarding_Onboarding_steps) =>
        step.stepType === StepType.ADD_ANOTHER_USER
    )?.stepStatus !== StepStatus.COMPLETED &&
    totalData.searchAgents &&
    totalData.searchAgents.total > 1;

  const shouldMarkTeamStepAsComplete = (
    onboardingDataInside: OnboardingDataWithMappedSteps | null,
    totalData: SearchTotalQuery
  ) =>
    onboardingDataInside &&
    onboardingDataInside.steps.find(
      (step: GetOnboardingQuery_getOnboarding_Onboarding_steps) =>
        step.stepType === StepType.CREATE_TEAM
    )?.stepStatus !== StepStatus.COMPLETED &&
    totalData.searchTeams &&
    totalData.searchTeams.total > 0;

  useEffect(() => {
    if (!showOnboardingTrial || noOnboardingData || !totals) {
      return;
    }

    if (shouldMarkUserStepAsComplete(onboardingData, totals)) {
      updateOnboardingStepStatus(StepType.ADD_ANOTHER_USER, StepStatus.COMPLETED);
    }
    if (shouldMarkTeamStepAsComplete(onboardingData, totals)) {
      updateOnboardingStepStatus(StepType.CREATE_TEAM, StepStatus.COMPLETED);
    }
  }, [onboardingData, noOnboardingData, totals, showOnboardingTrial, updateOnboardingStepStatus]);

  return {
    onboardingData,
    phoneUrl,
    completedSteps,
    allStepsCompleted,
    totalSteps,
    loading: loading || totalsLoading || createOnboardingLoading,
    createOnboardingData,
    updateOnboardingStepStatus,
    updateStepLoading,
    showConfetti: allStepsCompleted && !confettiShown,
    updateConfettiShown,
    refetch,
  };
};
