/* eslint-disable @typescript-eslint/naming-convention */
import { ExternalLink } from '../SidenavAccount.styles';

import {
  SidenavDropdown,
  SidenavDropdownItem,
  SidenavDropdownItemLabel,
  SidenavItemIndicator,
  SidenavItemTitle,
} from '@aircall/tractor-v2';
import { ReactComponent as SupportIcon } from '@assets/icons/sidenav/support.svg';
import { useAdminFeatures } from '@hooks/useFeatures/useAdminFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export function SupportRoutes({ expanded }: Readonly<{ expanded: boolean }>) {
  const { t } = useTranslation();

  const { track } = useTracker();

  const {
    currentUser: { ID: userId },
    currentCompany: { id: companyId },
  } = useGlobalData();

  const onSupportLinkClicked = (url: string) => () => {
    track({
      event: 'external_link_opened',
      payload: {
        link_destination_domain: url,
      },
    });
  };

  const { isSupportLinksAllowed } = useAdminFeatures();

  if (!isSupportLinksAllowed) {
    return null;
  }

  const knowledgeBaseLink = t('sidenav.help_and_feedback.knowledge_base_link');
  const learningLabLink = t('sidenav.help_and_feedback.learning_lab_link');
  const contactSupportTitle = t('sidenav.help_and_feedback.contact_support_link');
  const feedbackLink = t('sidenav.help_and_feedback.feedback_link', {
    url: window.location.href,
    userId,
    companyId,
  });

  return (
    <SidenavDropdown
      indicator={
        <SidenavItemIndicator color='icon-interactive-secondary'>
          <SupportIcon />
        </SidenavItemIndicator>
      }
      title={<SidenavItemTitle>{t('sidenav.help_and_feedback.title')}</SidenavItemTitle>}
      tooltip={t('sidenav.help_and_feedback.title')}
      expanded={expanded}
      component='button'
    >
      <SidenavDropdownItem
        component={ExternalLink}
        active='current-page'
        to={knowledgeBaseLink}
        onClick={onSupportLinkClicked(knowledgeBaseLink)}
        data-test='help-knowledge-base'
      >
        <SidenavDropdownItemLabel>
          {t('sidenav.help_and_feedback.knowledge_base_title')}
        </SidenavDropdownItemLabel>
      </SidenavDropdownItem>

      <SidenavDropdownItem
        component={ExternalLink}
        active='current-page'
        to={learningLabLink}
        onClick={onSupportLinkClicked(learningLabLink)}
        data-test='help-learning-lab'
      >
        <SidenavDropdownItemLabel>
          {t('sidenav.help_and_feedback.learning_lab_title')}
        </SidenavDropdownItemLabel>
      </SidenavDropdownItem>

      <SidenavDropdownItem
        component={ExternalLink}
        active='current-page'
        to={contactSupportTitle}
        onClick={onSupportLinkClicked(contactSupportTitle)}
        data-test='help-contact-support'
      >
        <SidenavDropdownItemLabel>
          {t('sidenav.help_and_feedback.contact_support_title')}
        </SidenavDropdownItemLabel>
      </SidenavDropdownItem>

      <SidenavDropdownItem
        component={ExternalLink}
        active='current-page'
        to={feedbackLink}
        onClick={onSupportLinkClicked(feedbackLink)}
        data-test='help-feedback'
      >
        <SidenavDropdownItemLabel>
          {t('sidenav.help_and_feedback.feedback_title')}
        </SidenavDropdownItemLabel>
      </SidenavDropdownItem>
    </SidenavDropdown>
  );
}
