import { Flex, SidenavItemProps, getRadii } from '@aircall/tractor-v2';
import { INLINE_END_OUTLINE_OFFSET } from '@components/Sidenav/Sidenav.utils';
import styled, { css, getSpace, keyframes } from '@xstyled/styled-components';

const fadeInDelayed = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

interface StyledContainerProps {
  expanded: boolean;
  shouldAnimate: boolean;
}

/**
 * This component will be used once we add
 * AgentWorkspaceRoutes to SidenavAccount
 */
/* istanbul ignore next */
export const ContainerOfFourItems = styled(Flex)<StyledContainerProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${getSpace('xxxs')};
  border-radius: ${getRadii('full')};
  background-color: #fbfbfb;
  gap: ${getSpace('xxs')};
  inline-size: 54px;
  margin-block-end: 20px;
  max-inline-size: 54px;
  transition: inline-size 0s;

  @supports (scrollbar-gutter: stable) {
    margin-inline-end: ${INLINE_END_OUTLINE_OFFSET * 2}px;
  }

  ${({ expanded, shouldAnimate }) =>
    expanded &&
    css`
      flex-direction: row;
      inline-size: 224px;
      max-inline-size: 224px;
      transition: inline-size 0.3s ease;

      ${shouldAnimate &&
      css`
        & > :nth-child(n + 2) {
          animation: ${fadeInDelayed} 0.6s ease;
          animation-delay: 0s;
          animation-duration: 0.6s;
        }
      `}
    `}
`;

/**
 * This component will be deleted once we add
 * AgentWorkspaceRoutes to SidenavAccount
 */
/* istanbul ignore next */
export const Container = styled(Flex)<StyledContainerProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${getSpace('xxxs')};
  border-radius: 40px;
  background-color: #fbfbfb;
  gap: ${getSpace('xxs')};
  transition: border-radius 150ms 150ms ease;

  @supports (scrollbar-gutter: stable) {
    margin-inline-end: ${INLINE_END_OUTLINE_OFFSET}px;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      border-radius: ${getRadii('default')};
      transition: border-radius 0s;
    `}
`;

export const ExternalLink = styled.a.attrs<SidenavItemProps>(({ to }) => ({
  href: to,
  target: '_blank',
}))<SidenavItemProps>``;
