import { Button, Flex, getSpace } from '@aircall/tractor-v2';
import { paddingInlineEndForOutlineOffset } from '@components/Sidenav/Sidenav.utils';
import styled, { css } from '@xstyled/styled-components';

export const ToggleButton = styled(Button)`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  block-size: ${getSpace('l')};
  inline-size: ${getSpace('l')};
`;

type StyledHeaderProps = {
  $expanded: boolean;
};

export const Header = styled(Flex)<StyledHeaderProps>`
  justify-content: ${({ $expanded }) => ($expanded ? 'flex-end' : 'center')};
  inline-size: 240px;
  min-inline-size: 240px;
  padding-block-end: ${getSpace('xxxs')};
  transition: inline-size ease 300ms, min-inline-size 300ms 0s, justify-content 0.3s ease;

  ${paddingInlineEndForOutlineOffset};

  ${ToggleButton} {
    transition: transform 0.3s 0.2s ease;
  }

  ${({ $expanded }) =>
    !$expanded &&
    css`
      inline-size: 54px;
      min-inline-size: 54px;

      ${ToggleButton} {
        transform: rotate(180deg);
      }
    `}
`;
