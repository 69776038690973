import { useMemo } from 'react';

import { NumbersBodySection } from './NumbersBodySection/NumbersBody.section';
import { NumberEmptyView } from './NumbersEmptyView/NumbersEmptyView.section';
import { NumbersHeaderSection } from './NumbersHeaderSection/NumbersHeader.section';

import { Flex, OrderDirection, OrderInput } from '@aircall/tractor-v2';
import CreateNumberDropdown from '@components/CreateNumberDropdown/CreateNumberDropdown';
import { DownloadExtract } from '@components/DownloadExtract';
import { FFEnabled } from '@components/FFEnabled';
import { APP_CONFIG } from '@constants/environment.constants';
import { DEFAULT_LINES_SORT_FIELD } from '@constants/generic.constants';
import { Placeholder } from '@dashboard/extension';
import {
  Gap,
  LIST_VIEW_PARAMS,
  PageHeader,
  useListViewFilters,
  useLoadMore,
  useTracker,
} from '@dashboard/library';
import { GenerateLinesExtractMutation } from '@generated/GenerateLinesExtractMutation';
import { SearchLinesQuery, SearchLinesQueryVariables } from '@generated/SearchLinesQuery';
import { GENERATE_LINES_EXTRACT_MUTATION } from '@graphql/mutations/GenerateLinesExtractMutation';
import { SEARCH_LINES_QUERY } from '@graphql/queries/SearchLinesQuery';
import { getSearchLinesFilter, trackFilterUsed } from '@helpers/search.helpers';
import { getSearchLinesSort } from '@helpers/sort.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useTranslation } from 'react-i18next';

export function getTotal(data: SearchLinesQuery | null | undefined): number {
  return data?.searchLines?.total ?? 0;
}

export function NumbersListSection() {
  const { smartflowMigrationEnabled, showOnboardingTrial } = useFeaturesFlags();
  const { track } = useTracker({ release: APP_CONFIG.release });
  const { get, setMultiple } = useListViewFilters({
    onSet: trackFilterUsed(track),
  });
  const { t } = useTranslation();
  const { entitiesCount } = useGlobalData();
  const hasNoNumber = entitiesCount.totalNumbers === 0 && !entitiesCount.loading;

  const sortField = get(LIST_VIEW_PARAMS.SORT_FIELD, DEFAULT_LINES_SORT_FIELD);
  const sortDirection = get(LIST_VIEW_PARAMS.SORT_DIRECTION, OrderDirection.Asc);
  const searchQuery = get(LIST_VIEW_PARAMS.SEARCH) ?? '';
  const countries = get(LIST_VIEW_PARAMS.COUNTRY);
  const types = get(LIST_VIEW_PARAMS.LINE_TYPE);
  const statuses = get(LIST_VIEW_PARAMS.LINE_STATUS);

  const filter = useMemo(
    () => getSearchLinesFilter({ searchQuery, countries, types, statuses }),
    // We pass dependencies arrays as strings to avoid filter to change reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, countries?.toString(), types?.toString(), statuses?.toString()]
  );

  const tableOrder: OrderInput = { field: sortField, direction: sortDirection };
  const sort = useMemo(
    () => getSearchLinesSort({ direction: sortDirection, field: sortField }),
    [sortDirection, sortField]
  );

  const { loading, data, staleData, hasMore, loadMore } = useLoadMore<
    SearchLinesQuery,
    SearchLinesQueryVariables
  >({
    query: SEARCH_LINES_QUERY,
    queryOptions: {
      variables: {
        filter,
        sort,
      },
      fetchPolicy: 'cache-and-network',
    },
    getTotal,
  });

  const handleOrderChange = (field: string, direction: OrderDirection) => {
    setMultiple({
      [LIST_VIEW_PARAMS.SORT_FIELD]: field,
      [LIST_VIEW_PARAMS.SORT_DIRECTION]: direction,
    });
  };

  return (
    <Flex data-test='numbers-list-section' flexDirection='column' h='100%'>
      <PageHeader
        largeTitle={t('numbers.title')}
        data-test='numbers-page-header'
        renderTitleRight={() => (
          <Gap gap='s'>
            <CreateNumberDropdown size='small' buttonOnly={showOnboardingTrial} />
            <FFEnabled featureFlag={(featureFlags) => Boolean(featureFlags.dashboardExtractReport)}>
              <DownloadExtract<GenerateLinesExtractMutation>
                mutation={GENERATE_LINES_EXTRACT_MUTATION}
                getUrl={
                  /* istanbul ignore next */
                  (d) => d.generateLinesExtract.extractFileUrl
                }
              />
            </FFEnabled>
          </Gap>
        )}
        titleProps={{ justifyContent: 'space-between' }}
      />
      {smartflowMigrationEnabled && (
        <Placeholder
          name='flow_editor_numbers_banner_migration'
          parameters={{}}
          data-test='flow_editor_numbers_banner_migration'
        />
      )}
      {hasNoNumber ? (
        <NumberEmptyView />
      ) : (
        <Gap flexDirection='column' gap='s' p='xl' py='m' w='100%' overflow='hidden' flex='1'>
          <NumbersHeaderSection totalNumbers={data?.searchLines?.total} />

          <NumbersBodySection
            lines={staleData?.searchLines?.items}
            loading={loading && !data}
            loadingMore={loading && !!staleData}
            onLoadMore={loadMore}
            onOrderChange={handleOrderChange}
            order={tableOrder}
            hasMore={hasMore}
          />
        </Gap>
      )}
    </Flex>
  );
}
