import { Button, Box, getColor, Flex, Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { LinkButton } from '@components/TrialChecklistPopup/TrialChecklistPopup.style';
import styled, { keyframes } from '@xstyled/styled-components';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';

export type TrialChecklistContainerProps = {
  popupMode: boolean;
};

const slideInAndFade = keyframes`
  from {
    transform: translateX(-120%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const TrialChecklistContainer = styled(Box)<TrialChecklistContainerProps>`
  width: 232px;
  padding: 16px 12px;
  border-radius: 16px;
  animation: ${slideInAndFade} 300ms ease-in-out;
  background-color: #fbfbfb;
  transition: inline-size ease 300ms, min-inline-size 300ms 0s;

  ${({ popupMode }) =>
    popupMode &&
    `
      position: absolute;
      left: 78px;
      bottom: 0;
      z-index: 2;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      transform: translateY(0);
      transition: transform 0.3s ease-in-out;
    `}
`;

export type TrialChecklistFullPageButtonProps = {
  visible: boolean;
};

export const TrialChecklistFullPageButton = styled(Button)<TrialChecklistFullPageButtonProps>`
  width: 124px;
  height: 32px;
  border-radius: 999px;
  visibility: ${({ visible }) => (visible ? 'none' : 'hidden')};
`;

export const TrialChecklistItemIconWrapper = styled(Flex)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  margin-right: 8px;
  background-color: ${getColor('surface-info')};
`;

export type TrialChecklistItemContainerProps = {
  disabled?: boolean;
};

export const TrialChecklistItemContainer = styled(Flex)<TrialChecklistItemContainerProps>`
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid ${getColor('secondary-100')};
  border-radius: 12px;
  margin: 0 0 8px;
  background-color: ${getColor('surface-interactive-default')};
  box-shadow: 0 2px 2px 0 rgba(0 0 0 / 0.08);
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover,
  &:active,
  &:focus {
    border-width: 1px;
    background-color: ${({ disabled }) =>
      disabled ? getColor('surface-interactive-default') : getColor('secondary-50')};
    box-shadow: ${({ disabled }) => (disabled ? 'none' : `0 0 0 1px ${getColor('secondary-100')}`)};
  }

  > :first-child {
    min-width: 0;
    flex: 1;
  }

  > :nth-child(2) {
    flex: 0 0 auto;
  }
`;

export const TrialChecklistItemExternalLink = styled(ExternalLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0 12px 12px;

  &:hover {
    text-decoration: none;
  }
`;

export const TrialChecklistItemTitle = styled(Typography)`
  overflow: hidden;
  width: 133px;
  flex-grow: 1;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TrialChecklistConfetti = styled(Fireworks)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
`;

export const TrialUpgradeLinkButton = styled(LinkButton)`
  border-color: ${getColor('surface-interactive-primary')};
  border-radius: 999px;
  background-color: ${getColor('surface-interactive-primary')};

  :hover {
    border-width: 2px;
    text-decoration: none;
  }
`;

export const TrialWorkspaceLinkButton = styled(LinkButton)`
  border-color: ${getColor('graphic-interactive-secondary')};
  border-radius: 999px;

  :hover {
    text-decoration: none;
  }
`;
