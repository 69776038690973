import {
  FetchResult,
  ApolloCache,
  FieldMergeFunction,
  FieldFunctionOptions,
  FieldPolicy,
  FieldReadFunction,
} from '@apollo/client';
import { CompanyIntegrationsQuery } from '@generated/CompanyIntegrationsQuery';
import { CreateBridgeIntegrationMutation } from '@generated/CreateBridgeIntegrationMutation';
import { PaginatedCallConversations } from '@generated/PaginatedCallConversations';
import { PaginatedEvaluations } from '@generated/PaginatedEvaluations';
import { COMPANY_INTEGRATIONS_QUERY } from '@graphql/queries/CompanyIntegrationsQuery';
import uniqBy from 'lodash-es/uniqBy';

export interface SearchableCollection<T> {
  items?: T[];
  total: number;
}

/**
 * Add the newly created Bridge integration to the integrations list cache.
 * @param logo - logo of the integration
 * @returns a function that receives apollo's cache and the result from the fetch
 */
export function addBridgeIntegrationToListCache<T>(logo: string | null) {
  return (cache: ApolloCache<T>, result: FetchResult<CreateBridgeIntegrationMutation>): void => {
    const integrationsQueryCache = cache.readQuery<CompanyIntegrationsQuery>({
      query: COMPANY_INTEGRATIONS_QUERY,
    });
    if (integrationsQueryCache) {
      const { companyIntegrations } = integrationsQueryCache;
      const {
        id,
        active,
        customName,
        configurations,
        serviceName: name,
        serviceNameUnderscored: nameUnderscored,
      } = result.data!.createRestBridgeIntegration;
      cache.modify({
        fields: {
          companyIntegrations: () => ({
            ...companyIntegrations,
            data: [
              ...companyIntegrations.data,
              {
                ...result.data!.createRestBridgeIntegration,
                id: id.toString(),
                __typename: 'CompanyIntegration',
                attributes: {
                  __typename: 'RestIntegration',
                  id,
                  logo,
                  active,
                  customName,
                  name,
                  nameUnderscored,
                  numbersConnected: configurations!.numbers.length,
                  service: 'bridge',
                },
                type: 'integration',
              },
            ],
          }),
        },
      });
    }
  };
}

/**
 * Merge two arrays which share elements with given identifier.
 * @param identifier - key of the object
 * @returns the merged version of the two list if incoming is defined
 */
export function mergeListBy<T>(
  identifier: keyof T
): FieldMergeFunction<T[] | undefined, T[] | null> {
  // eslint-disable-next-line default-param-last
  return (existing = [], incoming) => {
    if (!incoming) {
      // Return existing if incoming value is null
      return existing;
    }

    const incomingIds = incoming.map((item) => item[identifier]);
    const baseData = existing.filter((item) => !incomingIds.includes(item[identifier]));

    return [...baseData, ...incoming];
  };
}

const defaultCheck = (args: Record<string, unknown>) => args.from === 0;
/**
 * Merge collection of search items together.
 * @param resetCacheValue - use to determine if the cache value for a query needs to start again
 * without the existing cache value based on the query argument
 *
 * Example: when we move to page with a query with 'cache-and-network' fetch policy then
 * if we move out / move in back to the page the query's cache should not merge again the
 * incoming result to the existing result
 *
 * @param existing - first collection
 * @param incoming - second collection
 * @param apolloFieldFunctionOptions - apollo's FieldFunctionOptions
 * @returns merged collection
 */
export const mergeSearchItemsCollection =
  (resetCacheValue = defaultCheck) =>
  <T>(
    existing: SearchableCollection<T>,
    incoming: SearchableCollection<T>,
    { args, field }: FieldFunctionOptions
  ): SearchableCollection<T> => {
    // unique behavior for field with alias
    if (field?.alias) {
      return incoming;
    }

    if (!incoming.items && existing?.items) {
      return { ...existing, ...incoming };
    }

    // if `incoming` doesn't have any items, skip merging
    if (!args || resetCacheValue(args) || !incoming.items) {
      return incoming;
    }

    const mergedItems = existing?.items ? existing.items.slice(0) : [];
    const start = args?.from || mergedItems.length;
    const end = start + incoming.items.length;

    // eslint-disable-next-line no-plusplus
    for (let i = start; i < end; i++) {
      mergedItems[i] = incoming.items[i - start];
    }

    return { ...incoming, items: mergedItems };
  };

export type ListWithToken = {
  items: unknown[];
  pageInfo: {
    currentToken: string;
    previousToken: string | null;
    nextToken: string | null;
  };
};

export type ListWithTokenVariables = {
  input: {
    pages?: 'CURRENT' | 'FROM_FIRST';
    filter: unknown;
    sort: unknown;
  };
};

export const mergeTokenBasedList: FieldMergeFunction<
  ListWithToken | undefined,
  ListWithToken,
  FieldFunctionOptions
> = (existing, incoming, { variables }) => {
  // Could not find a way to pass ListWithTokenVariables as generic to FieldFunctionOptions
  // without breaking cache.config
  // If you manage to do it, go ahead
  const vars = variables as ListWithTokenVariables;

  // Merge when incoming is the next page
  if (existing && vars.input.pages !== 'FROM_FIRST') {
    const existingNextToken = existing.pageInfo.nextToken;
    const incomingCurrentToken = incoming.pageInfo.currentToken;

    if (incomingCurrentToken === existingNextToken) {
      const existingItems = existing.items;
      const incomingItems = incoming.items;

      const newData: ListWithToken = {
        ...incoming,
        items: [...existingItems, ...incomingItems],
      };

      return newData;
    }
  }

  return incoming;
};

/**
 * Merge list of call conversations together.
 * @param existing - current list
 * @param incoming - fetched list
 * @returns merged lists
 */
export function mergeCallConversations(
  existing: PaginatedCallConversations['getCallConversations'] | undefined,
  incoming: PaginatedCallConversations['getCallConversations']
): PaginatedCallConversations['getCallConversations'] {
  const shouldMerge = existing && existing.pageInfo?.nextToken === incoming!.pageInfo.currentToken;

  if (shouldMerge) {
    return {
      __typename: 'PaginatedCallConversations',
      pageInfo: incoming!.pageInfo,
      // the aggregation could be null after the first page
      aggregations: incoming!.aggregations,
      items: [...existing.items, ...incoming!.items],
    };
  }

  return incoming;
}

export function mergeEvaluations(
  existing: PaginatedEvaluations['getEvaluations'] | undefined,
  incoming: PaginatedEvaluations['getEvaluations']
): PaginatedEvaluations['getEvaluations'] | undefined {
  if (
    incoming.__typename !== 'PaginatedEvaluations' ||
    (existing && existing.__typename !== 'PaginatedEvaluations')
  ) {
    return existing;
  }

  const shouldMerge = existing && existing.pageInfo?.nextToken === incoming!.pageInfo.currentToken;

  if (shouldMerge) {
    return {
      __typename: 'PaginatedEvaluations',
      pageInfo: incoming!.pageInfo,
      items: [...existing.items, ...incoming!.items],
    };
  }

  return incoming;
}

export interface UserAssociatedLinesCache {
  items: { __ref: string }[];
}

/**
 * Merge list of a user associated lines
 * @param existing - existing associated line items
 * @param incoming - incoming associated line items
 * @returns merge list
 */
export function mergeUserAssociatedLines(
  existing: UserAssociatedLinesCache | undefined,
  incoming: UserAssociatedLinesCache
): UserAssociatedLinesCache {
  const uniqueUserNumbers = uniqBy([...(existing?.items ?? []), ...incoming.items], '__ref');
  return { ...incoming, items: uniqueUserNumbers };
}

export interface GetInvoicesCache {
  total?: number;
  invoices?: ({ __ref: string } | null)[];
}

type GetInvoicesArgs = {
  page: number;
  limit: number;
};

export function getInvoicesPagination(): {
  keyArgs: FieldPolicy<GetInvoicesArgs | undefined>['keyArgs'];
  merge: FieldMergeFunction<GetInvoicesCache | undefined>;
  read: FieldReadFunction<GetInvoicesCache | undefined>;
} {
  /**
   * Calculates the index of the first item based on page and limit.
   */
  function calculateFirstItemIndex(page: number, limit: number): number {
    /**
     * We subtract 1 from the page because the page is 1-based
     */
    return (page - 1) * limit;
  }

  /**
   * Calculates the index of the last item based on the firstItemIndex, limit, and total.
   */
  function calculateLastItemIndex(firstItemIndex: number, limit: number, total: number): number {
    /**
     * We use Math.min to ensure that we don't go over the total,
     * and subtract 1 from the total to obtain a 0-based index.
     */
    return Math.min(firstItemIndex + limit, total) - 1;
  }

  /**
   * Calculates the index of the last item based on the firstItemIndex, limit, and total.
   */
  function hasPagination(args: Record<string, number> | null): args is GetInvoicesArgs {
    return Boolean(args?.page) && Boolean(args?.limit);
  }

  return {
    keyArgs: false,
    merge(existing, incoming, { args }) {
      /**
       * If there's no pagination, we don't need to merge the data.
       */
      if (!hasPagination(args)) {
        return incoming;
      }

      const total = incoming?.total ?? 0;
      /**
       * We create a new array with the same length as the total number of invoices.
       * We fill it with null values to represent the missing invoices, so that we can
       * handle it properly in a paginated table.
       */
      const invoices = existing?.invoices ? existing.invoices.slice(0) : Array(total).fill(null);

      const incomingInvoices = incoming?.invoices ?? [];

      if (incomingInvoices.length) {
        const { page, limit } = args;

        const firstItemIndex = calculateFirstItemIndex(page, limit);

        for (let i = 0; i < incomingInvoices.length; i += 1) {
          invoices[firstItemIndex + i] = incomingInvoices[i];
        }
      }

      return {
        ...incoming,
        total,
        invoices,
      };
    },
    /**
     * Function to be called before the merge function to determine if the cache should be read.
     * If the cache is not valid, it should return undefined, to trigger a GraphQL server call.
     * If the cache is valid, it should return the existing cache.
     *
     * More info here: https://www.apollographql.com/docs/react/pagination/core-api/#paginated-read-functions
     */
    read(existing, { args }) {
      /**
       * If there's no pagination, we simply return the existing data.
       */
      if (!hasPagination(args)) {
        return existing;
      }

      const existingInvoices = existing?.invoices ?? [];
      const total = existing?.total ?? existingInvoices.length;

      if (existingInvoices.length) {
        const { page, limit } = args;

        const firstItemIndex = calculateFirstItemIndex(page, limit);

        const lastItemIndex = calculateLastItemIndex(firstItemIndex, limit, total);

        for (let i = firstItemIndex; i <= lastItemIndex; i += 1) {
          /**
           * If we identify there's any missing invoices in the current range, we return undefined
           */
          if (!existingInvoices[i]) {
            return undefined;
          }
        }
      }

      return existing;
    },
  };
}
