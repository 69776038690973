import styled from 'styled-components';

import { FormFlowPageProps } from '../FormFlow.decl';
import { useFormFlow } from '../useFormFlow';

import { FormFlowPageErrorBanner } from './FormFlowPageErrorBanner';
import { FormFlowPageFooter } from './FormFlowPageFooter';
import { FormFlowPageHeader } from './FormFlowPageHeader';

import { Flex, FlexProps } from '@aircall/tractor-v2';
import { Loading } from '@dashboard/library';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';

const Container = styled(Flex)<FlexProps & Pick<FormFlowPageProps, 'showHeader' | 'showFooter'>>`
  position: fixed;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
  inset: 0; /* This sets top, right, bottom, and left to 0 */
`;

const Body = styled(Flex)`
  overflow: auto;
  grid-area: body;
`;

export function FormFlowPage({
  children,
  'data-test': dataTest = 'form-flow-page',
  showHeader = false,
  headerTitle,
  headerIcon,
  showErrorBanner = true,
  showFooter = false,
  loading = false,
  errorBannerComponent,
  // Next
  showFooterNext = true,
  nextText,
  nextDisabled: nextDisabledProp = false,
  // Secondary
  secondaryText,
  secondaryDisabled = false,
  onSecondary,
  // Cancel
  showFooterCancel = true,
  cancelText,
}: FormFlowPageProps) {
  const { onCancel, onClickNext, buttonLoading, nextDisabled: nextDisabledContext } = useFormFlow();
  const nextDisabled = nextDisabledProp || nextDisabledContext;
  const { dashboardEnabledNewSidebar } = useFeaturesFlags();

  return (
    <Container
      showHeader={showHeader}
      showFooter={showFooter}
      data-test={dataTest}
      backgroundColor={dashboardEnabledNewSidebar ? 'surface-default' : 'surface-background'}
    >
      {showHeader && (
        <FormFlowPageHeader
          data-test={`${dataTest}-header`}
          headerTitle={headerTitle}
          headerIcon={headerIcon}
        />
      )}

      {loading ? (
        <Loading data-test={`${dataTest}-loading`} />
      ) : (
        <Body data-test={`${dataTest}-body`}>{children}</Body>
      )}

      {!showFooter && showErrorBanner && (
        <FormFlowPageErrorBanner component={errorBannerComponent} />
      )}

      {showFooter && (
        <FormFlowPageFooter
          data-test={`${dataTest}-footer`}
          showErrorBanner={showErrorBanner}
          // Next
          nextText={nextText}
          nextLoading={buttonLoading}
          nextDisabled={nextDisabled}
          showNext={showFooterNext}
          onNext={onClickNext}
          // Secondary
          secondaryText={secondaryText}
          onSecondary={onSecondary}
          secondaryDisabled={secondaryDisabled}
          // Cancel
          showCancel={showFooterCancel}
          errorBannerComponent={errorBannerComponent}
          cancelText={cancelText}
          onCancel={onCancel}
        />
      )}
    </Container>
  );
}
