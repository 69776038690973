import { ChevronDownOutlined } from '@aircall/icons';
import { Button, Dropdown, Menu, MenuItem } from '@aircall/tractor-v2';
import { CONTACT_US_KNOWLEDGE_BASE_URL, SUPPORT_URL } from '@constants/urls.constants';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export function SupportLinksDropdown() {
  const { t } = useTranslation();
  const { track } = useTracker();
  const {
    currentUser: { ID: userId },
    currentCompany: { id: companyId },
  } = useGlobalData();
  const { dashboardEnabledNewSidebar } = useFeaturesFlags();

  if (dashboardEnabledNewSidebar) {
    return null;
  }

  function handleMenuItemLink(url: string) {
    return () => {
      track({
        event: 'external_link_opened',
        payload: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          link_destination_domain: url,
        },
      });

      window.open(url, '_blank');
    };
  }

  const onFeedbackClick = () => {
    const url = window.location.href;
    window.open(t('header.feedback_button.url', { url, userId, companyId }), '_blank');
  };

  return (
    <Dropdown
      trigger={
        <Button
          data-test='contact-us-dropdown-button'
          size='xSmall'
          mode='ghost'
          variant='secondary'
        >
          {t('header.contact_us_dropdown.title')}
          <ChevronDownOutlined />
        </Button>
      }
    >
      <Menu>
        <MenuItem
          onClick={handleMenuItemLink(CONTACT_US_KNOWLEDGE_BASE_URL)}
          size='regular'
          data-test='contact-us-knowledge-base-dropdown-link'
        >
          {t('header.contact_us_dropdown.knowledge_base')}
        </MenuItem>
        <MenuItem
          onClick={handleMenuItemLink(t('header.contact_us_dropdown.learning_lab_link'))}
          size='regular'
          data-test='contact-us-learning-lab-dropdown-link'
        >
          {t('header.contact_us_dropdown.learning_lab')}
        </MenuItem>
        <MenuItem
          onClick={handleMenuItemLink(SUPPORT_URL)}
          size='regular'
          data-test='contact-us-report-issue-dropdown-link'
        >
          {t('header.contact_us_dropdown.contact_support')}
        </MenuItem>
        <MenuItem onClick={onFeedbackClick} size='regular' data-test='header-feedback-btn'>
          {t('header.feedback_button.title')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
