import { useState, useEffect } from 'react';

import { SidenavTrialProps } from './SidenavTrial.decl';
import { Container, StyledSidenavItem } from './SidenavTrial.styles';
import { TrialChecklist } from './subcomponents/TrialChecklist/TrialChecklist';

import { TrialChecklistButton } from '@components/Sidenav/components/SidenavTrial/subcomponents/TrialChecklistButton/TrialChecklistButton';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useOnboardingData } from '@hooks/useOnboardingData';
import { useTranslation } from 'react-i18next';

export function SidenavTrial({ expanded }: Readonly<SidenavTrialProps>) {
  const { t } = useTranslation();
  const { showOnboardingTrial } = useFeaturesFlags();
  const { completedSteps, loading, totalSteps, onboardingData, createOnboardingData, phoneUrl } =
    useOnboardingData();

  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const showTrialPopupInWidget = !expanded && isWidgetOpen;
  const showTrialWidget = expanded || showTrialPopupInWidget;

  useEffect(() => {
    createOnboardingData();
  }, [createOnboardingData]);

  // Disabled Trial Sidenav
  if (!showOnboardingTrial || loading || !onboardingData) {
    return null;
  }

  return (
    <Container expanded={expanded}>
      {!expanded && (
        <StyledSidenavItem
          key='sidenav-trial-checklist-popper'
          indicator={<TrialChecklistButton totalTask={totalSteps} completedTask={completedSteps} />}
          tooltip={t('sidenav.trial_todos.title')}
          component='button'
          onClick={() => {
            setIsWidgetOpen(!isWidgetOpen);
          }}
          title={t('sidenav.trial_todos.title')}
          expanded={false}
          style={{
            justifyContent: 'center',
          }}
        />
      )}
      {showTrialWidget && (
        <TrialChecklist
          totalTask={totalSteps}
          completedTask={completedSteps}
          onboardingData={onboardingData}
          popupMode={showTrialPopupInWidget}
          setIsWidgetOpen={setIsWidgetOpen}
          phoneUrl={phoneUrl}
        />
      )}
    </Container>
  );
}
