import { gql } from '@apollo/client';

export const INTEGRATION_AVAILABLE_UPDATE_QUERY = gql`
  query GetIntegrationsToUpdateQuery($requireUpdate: Boolean) {
    getIntegrations(requireUpdate: $requireUpdate) {
      active
      applicationId
      customName
      expiresAt
      migrationMetadata
      id
      logo
      name
      nameUnderscored
      numbersConnected
      serviceName
    }
  }
`;
