/* eslint-disable @typescript-eslint/naming-convention */
import { Button } from '@aircall/tractor-v2';
import { CountryFilter } from '@components/CountryFilter';
import { LineStatusFilter } from '@components/LineStatusFilter';
import { LineTypeFilter } from '@components/LineTypeFilter';
import { SearchBar } from '@components/SearchBar';
import { APP_CONFIG } from '@constants/environment.constants';
import {
  LIST_VIEW_PARAMS,
  useListViewFilters,
  usePersistedSearchParams,
  useTracker,
} from '@dashboard/library';
import { trackFilterUsed } from '@helpers/search.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useTranslation } from 'react-i18next';

export function LinesFilters() {
  const { t } = useTranslation();
  const { track } = useTracker({ release: APP_CONFIG.release });
  const { get, set, setDebounced } = useListViewFilters({
    onSet: trackFilterUsed(track),
  });
  const { deleteAllPersistedSearchParams } = usePersistedSearchParams();

  const { dashboardListViewFilters } = useFeaturesFlags();

  const searchQuery = get(LIST_VIEW_PARAMS.SEARCH) ?? '';
  const countries = get(LIST_VIEW_PARAMS.COUNTRY);
  const types = get(LIST_VIEW_PARAMS.LINE_TYPE);
  const status = get(LIST_VIEW_PARAMS.LINE_STATUS);

  function handleFilterDebounced(filter: LIST_VIEW_PARAMS) {
    return (value: string | string[]) => {
      setDebounced(filter, value);
    };
  }

  function handleFilter(filter: LIST_VIEW_PARAMS) {
    return (value: string | string[]) => {
      set(filter, value);
    };
  }

  function handleReset() {
    deleteAllPersistedSearchParams();
  }

  return (
    <>
      <SearchBar
        w='240px'
        data-test='search-number-input'
        name='numbers-search'
        size='small'
        value={searchQuery}
        placeholder={t('numbers.search.placeholder')}
        onSearch={handleFilter(LIST_VIEW_PARAMS.SEARCH)}
        onClear={() => handleFilter(LIST_VIEW_PARAMS.SEARCH)('')}
      />

      {dashboardListViewFilters && (
        <>
          <CountryFilter
            initialCountries={countries}
            onSelect={handleFilterDebounced(LIST_VIEW_PARAMS.COUNTRY)}
          />

          <LineTypeFilter
            initialTypes={types}
            onSelect={handleFilterDebounced(LIST_VIEW_PARAMS.LINE_TYPE)}
          />

          <LineStatusFilter
            initialStatuses={status}
            onSelect={handleFilterDebounced(LIST_VIEW_PARAMS.LINE_STATUS)}
          />

          <Button
            data-test='lines-filters-reset'
            mode='ghost'
            variant='secondary'
            onClick={handleReset}
          >
            {t('generic.reset')}
          </Button>
        </>
      )}
    </>
  );
}
