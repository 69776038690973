import { useRef } from 'react';

import { AddOption as AddDispatchGroupOption } from './AddDispatchGroupSection/AddOption';
import { AddOption as AddIvrSettingOption } from './AddIvrSettingSection/AddOption';
import { AfterhourSection } from './AfterhourSection/AfterhourSection';
import { ClassicNumberAudiosCard } from './ClassicNumberAudiosCard/ClassicNumberAudiosCard';
import { TRANSLATION_PREFIX } from './config';
import { DispatchGroups } from './DispatchGroups/DispatchGroups';
import { FallbackIvrSection } from './FallbackIvrSection/FallbackIvrSection';
import {
  DiagramHeaderWrapper,
  ColumnHeader,
  ArrowWrapper,
  DiagramContainer,
  DiagramBranch,
} from './FlowDiagram';
import { IvrNumberAudiosCard } from './IvrNumberAudiosCard';
import { getAvailableKeysOptions } from './IvrSettingModal/helpers';
import { IvrSettings } from './IvrSettings/IvrSettings';
import { NumberDetailCallDistributionProps } from './NumberDetailCallDistribution.decl';
import { ReplayIvrSection } from './ReplayIvrSection/ReplayIvrSection';
import { SVGVerticalArrowLine } from './SVGVerticalArrowLine';
import { UnansweredGroupSection } from './UnansweredGroupSection/UnansweredGroupSection';

import { CallFilled, CloseOutlined } from '@aircall/icons';
import {
  Box,
  Typography,
  Spacer,
  Tag,
  Icon,
  BannerIcon,
  IconButton,
  Banner,
  BannerHeading,
  BannerSuffix,
} from '@aircall/tractor-v2';
import { BusinessHoursState } from '@components/BusinessHoursState/BusinessHoursState';
import { logger } from '@config/logger.config';
import { SHOW_BANNER_IN_CALL_DISTRIBUTION } from '@constants/storage.constants';
import { Placeholder } from '@dashboard/extension';
import { GridItem, GridLayout } from '@dashboard/library';
import { LineState } from '@generated/globalTypes';
import {
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_Team,
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_User,
} from '@generated/NumberDetailQuery';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { ScrollableContainerProvider } from '@hooks/useScrollYContainer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

export function NumberDetailCallDistribution({ numberDetail }: NumberDetailCallDistributionProps) {
  const { t } = useTranslation();
  const { numberId } = useParams();
  const { smartflowMigrationEnabled } = useFeaturesFlags();
  const containerRef = useRef<HTMLDivElement>(null);

  const isIVR = numberDetail.ivr;
  const availableKeyOptions = isIVR
    ? getAvailableKeysOptions(numberDetail.ivrSettings.map((ivrSetting) => ivrSetting.key))
    : undefined;
  const ivrSettings = numberDetail.ivrSettings.filter((setting) => setting.key !== 'fallback');
  const ivrFallback = numberDetail.ivrSettings.find((setting) => setting.key === 'fallback');

  const [isInfoBannerVisible, setBannerVisibility] = useLocalStorage(
    SHOW_BANNER_IN_CALL_DISTRIBUTION,
    true
  );
  // Calculate priority for potential new dispatch group
  // Since priorities always look like [1,2,3] and never [1,3], we can take the length of the array
  const newPriority = numberDetail.dispatchGroups.length + 1;

  const usersAndTeamsIdsBlacklist = numberDetail.dispatchGroups.reduce<Set<string>>(
    (set, dispatchGroup) => {
      const dispatchable = dispatchGroup?.dispatchable;

      if (!dispatchable) {
        logger.error('Number with missing dispatchable', { numberId });
        return set;
      }

      const teamDispatchable =
        dispatchable as NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_Team;
      const userDispatchable =
        dispatchable as NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_User;
      const id = userDispatchable.id?.toString() || teamDispatchable.ID;
      set.add(id);
      return set;
    },
    new Set()
  );

  return (
    <ScrollableContainerProvider containerRef={containerRef}>
      {smartflowMigrationEnabled && (
        <Placeholder
          name='flow_editor_call_distribution_migration'
          parameters={{ lineId: numberId }}
          data-test='flow_editor_call_distribution_migration'
        />
      )}
      <Box ref={containerRef} h='100%' overflow='auto' p='40px'>
        <Box maxWidth='930px' m='0 auto'>
          {isInfoBannerVisible && (
            <Banner variant='info' inline mb='xl'>
              <BannerIcon />
              <BannerHeading>
                {t(`${TRANSLATION_PREFIX}.info-banner.personalize_message`)}
              </BannerHeading>
              <BannerSuffix>
                <IconButton
                  data-test='dismiss-info-banner-button'
                  onClick={() => {
                    setBannerVisibility(false);
                  }}
                  component={CloseOutlined}
                />
              </BannerSuffix>
            </Banner>
          )}
          <GridLayout position='relative' columnGap='0' rowGap='0'>
            <DiagramHeaderWrapper>
              <Spacer space='xxs' alignItems='center' paddingBottom='xs'>
                <Icon component={CallFilled} size={16} />
                <Typography variant='bodySemiboldM'>
                  {t(`${TRANSLATION_PREFIX}.call_comes_in`)}
                </Typography>
              </Spacer>
            </DiagramHeaderWrapper>

            {/* Left Column */}
            <GridItem xs={12} md={6} lg={6} justifySelf='center' w='100%'>
              {/* Header: Left Column */}
              <ColumnHeader isLeft>
                <Box position='relative' margin='0 auto'>
                  <Tag size='small' variant='white'>
                    <BusinessHoursState state={LineState.AUTO} open />
                  </Tag>
                </Box>
              </ColumnHeader>
              <ArrowWrapper>
                <SVGVerticalArrowLine height={20} />
              </ArrowWrapper>

              {/* Content: Left Column */}
              <DiagramContainer offsetBottom='25px' offsetTop='24px'>
                {isIVR ? (
                  <IvrNumberAudiosCard numberDetail={numberDetail} />
                ) : (
                  <ClassicNumberAudiosCard numberDetail={numberDetail} />
                )}

                {isIVR ? (
                  <IvrSettings
                    numberId={numberDetail.id}
                    items={ivrSettings}
                    availableKeyOptions={availableKeyOptions!}
                    countryCode={numberDetail.iso3166Alpha2}
                  />
                ) : (
                  <DispatchGroups numberId={numberDetail.id} groups={numberDetail.dispatchGroups} />
                )}

                {/* show AddOption component only if there're some available keys */}
                {isIVR ? (
                  !!availableKeyOptions?.length && (
                    <DiagramBranch branchOffsetTop='12px'>
                      <AddIvrSettingOption
                        keyOptions={availableKeyOptions}
                        text={t(`${TRANSLATION_PREFIX}.add_ivr_setting_section.add_option`)}
                      />
                    </DiagramBranch>
                  )
                ) : (
                  <DiagramBranch branchOffsetTop='12px'>
                    <AddDispatchGroupOption
                      numberId={numberDetail.id}
                      priority={newPriority}
                      idsBlacklist={usersAndTeamsIdsBlacklist}
                    />
                  </DiagramBranch>
                )}

                {!isIVR && !numberDetail.dispatchGroups.length && (
                  <Box mt='xs' ml='l'>
                    <Banner variant='critical'>
                      <BannerIcon />
                      <BannerHeading>
                        {t(`${TRANSLATION_PREFIX}.info-banner.add_team_or_member`)}
                      </BannerHeading>
                    </Banner>
                  </Box>
                )}

                {isIVR ? (
                  <ReplayIvrSection numberDetail={numberDetail} />
                ) : (
                  <UnansweredGroupSection numberDetail={numberDetail} />
                )}

                {isIVR && (
                  <FallbackIvrSection
                    ivrFallback={ivrFallback}
                    countryCode={numberDetail.iso3166Alpha2}
                  />
                )}
              </DiagramContainer>
            </GridItem>

            {/* Right Column */}
            <GridItem xs={12} md={6} lg={6} justifySelf='center' w='100%'>
              {/* Header: Right Column */}
              <ColumnHeader isLeft={false}>
                <Box position='relative' margin='0 auto'>
                  <Tag size='small' variant='white'>
                    <BusinessHoursState state={LineState.AUTO} open={false} />
                  </Tag>
                </Box>
              </ColumnHeader>
              <ArrowWrapper>
                <SVGVerticalArrowLine height={20} />
              </ArrowWrapper>

              {/* Content: Right Column */}
              <DiagramContainer offsetTop='24px' offsetBottom='11px'>
                <AfterhourSection numberDetail={numberDetail} />
              </DiagramContainer>
            </GridItem>
          </GridLayout>
        </Box>
      </Box>
    </ScrollableContainerProvider>
  );
}
