import React from 'react';

import { TrialChecklistProgress } from '../TrialChecklistProgress/TrialChecklistProgress';

import { TrialChecklistButtonProps } from './TrialChecklistButton.decl';
import { TrialChecklistButtonBox } from './TrialChecklistButton.styles';

export function TrialChecklistButton({
  completedTask,
  totalTask,
}: Readonly<TrialChecklistButtonProps>) {
  return (
    <TrialChecklistButtonBox data-test='trial-checklist-button-box'>
      <TrialChecklistProgress total={totalTask} completed={completedTask} />
    </TrialChecklistButtonBox>
  );
}
