import { AnalyticsDashboardType } from '@generated/globalTypes';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsCallQualityPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsPlusAddonPage
      feedbackButtonEvent='call_quality_analytics_feedback_button_clicked'
      pageHeaderTitle={t('stats.tabs.network_diagnostics')}
      title='call_quality'
      type={AnalyticsDashboardType.CALL_QUALITY}
    />
  );
}
