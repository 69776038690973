import { NUMBERS_ROUTE, ONBOARDING_ROUTE } from '@constants/routes.constants';
import { usePermissions } from '@hooks/usePermissions';
import { useOnboarding } from '@pages/onboarding/hooks/useOnboarding';
import { Navigate } from 'react-router';

/* istanbul ignore next */
export function Home() {
  const { isOnboardingRedirectActive } = useOnboarding();
  const { getAccessibleRoute } = usePermissions();
  const defaultRoute = getAccessibleRoute() || NUMBERS_ROUTE;
  let redirectTo;
  if (isOnboardingRedirectActive) {
    redirectTo = ONBOARDING_ROUTE;
  } else {
    redirectTo = defaultRoute;
  }
  return <Navigate to={redirectTo} />;
}
