import { UserCurrentUserReturn } from './useCurrentUser.decl';

import { GetCurrentUserQuery } from '@generated/GetCurrentUserQuery';
import { GET_CURRENT_USER_QUERY } from '@graphql/queries/GetCurrentUserQuery';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

/**
 * Hook to get the current user data.
 * @returns an object holding the query state and user's data
 */
export function useCurrentUser(): UserCurrentUserReturn {
  const { data, error, loading } = useGraphQuery<GetCurrentUserQuery>(GET_CURRENT_USER_QUERY);

  return {
    loading,
    error,
    data,
    currentUser: data?.getAgentV2,
  };
}
