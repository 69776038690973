import { getColor } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const TrialChecklistProgressWrapper = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
`;

export const BackgroundCircle = styled.circle`
  cx: 17.5;
  cy: 17.5;
  fill: none;
  r: 14;
  stroke: ${getColor('graphic-inverted')};
  stroke-width: 5;
`;

export const ProgressCircle = styled.circle<{ progress: number }>`
  cx: 17.5;
  cy: 17.5;
  fill: none;
  r: 14;
  stroke: ${getColor('icon-primary')};
  stroke-dasharray: 88;
  stroke-dashoffset: ${({ progress }) => 88 - (progress / 100) * 88};
  stroke-linecap: round;
  stroke-width: 5.3;
  transform: rotate(-90deg);
  transform-origin: center;
`;

export const TrialChecklistTextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${getColor('text-base')};
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
`;
