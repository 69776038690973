/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { APP_CONFIG } from '../../constants/environment.constants';
import { useGlobalData } from '../useGlobalData/useGlobalData';

import { TrackerIdentification } from '@aircall/tracker';
import { tracker } from '@constants/tracker.constants';
import { getBrowser } from '@dashboard/library';
import { AgentRole } from '@generated/globalTypes';
import { getCommonPayload, getSignedInAsPayload } from '@helpers/tracker.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';

/**
 * Hook to manage the tracker initialization and updates given the current user.
 */
export function useInitTracker(): void {
  const { currentCompany, currentUser, isTrial } = useGlobalData();
  const { disableFrontendTracking } = useFeaturesFlags();

  // initializes the tracker
  useEffect(() => {
    try {
      !disableFrontendTracking && tracker.init();
    } catch (e) {
      /* istanbul ignore next */
      console.log('error thrown at initialization of Rudderstack', e);
    }
  }, [disableFrontendTracking]);

  // updates the user info for the tracker once we got it
  useEffect(() => {
    !disableFrontendTracking &&
      tracker.identify({
        ...getCommonPayload(),
        ...getSignedInAsPayload(),
        device_details: getBrowser(),
        user_id: parseInt(currentUser.ID),
        company_id: currentCompany.id,
        company_name: currentCompany.name,
        company_plan: currentCompany.plan,
        language: currentUser.language.replace('-', '_'),
        company: {
          billing_period: currentCompany.billingPeriod,
          country: currentCompany.address?.country as string,
          created_at: currentCompany.createdAt,
          last_plan_change: currentCompany.lastTimePlanChanged,
          is_trial: isTrial,
          tier: currentCompany.tierLevel,
        },
        device: APP_CONFIG.device,
        email: currentUser.email,
        environment: APP_CONFIG.environment,
        is_admin: currentUser.role === AgentRole.admin,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        // do type casting here because we don't have `created_at` in currentUser
        // but it is required by TrackerIdentification
      } as TrackerIdentification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentCompany, disableFrontendTracking, isTrial]);
}
