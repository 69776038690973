import { AnalyticsDashboardProps } from './AnalyticsDashboard.decl';

import { FeedbackButton } from '@components/FeedbackButton';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { LookerPage } from '@pages/looker-v2/Looker.page';
import { ReportPage } from '@pages/looker/Report.page';

export function AnalyticsDashboard({
  feedbackButtonEvent,
  maxDateRangeInDays,
  minDate,
  onTruncated,
  pageHeaderTitle,
  title,
  type,
  pageHeaderProps,
}: AnalyticsDashboardProps) {
  const { showAnalyticsFiltersInDashboard } = useFeaturesFlags();

  return (
    <>
      {feedbackButtonEvent && (
        <FeedbackButton data-test='analytics-feedback-button' event={feedbackButtonEvent} />
      )}
      {showAnalyticsFiltersInDashboard ? (
        <LookerPage
          maxDateRangeInDays={maxDateRangeInDays}
          minDate={minDate}
          onTruncated={onTruncated}
          pageHeaderTitle={pageHeaderTitle}
          title={title}
          type={type}
          pageHeaderProps={pageHeaderProps}
        />
      ) : (
        <ReportPage onTruncated={onTruncated} title={title} type={type} />
      )}
    </>
  );
}
