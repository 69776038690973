import { Button, ButtonProps, IconButton, IconButtonProps, getColor } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const ButtonIcon = styled(IconButton)<IconButtonProps>`
  :hover {
    transform: scale(1.2);
  }

  :focus-visible {
    outline: auto;
  }
`;

export const LinkIcon = styled.a`
  display: inline-block;
  width: fit-content;
  height: auto;
  text-decoration: none;

  :hover {
    transform: scale(1.2);
  }
`;

export const FullPageButton = styled(Button)<ButtonProps & { $visible?: boolean }>`
  width: 124px;
  height: 32px;
  visibility: ${(props) => (props?.$visible ? 'hidden' : 'none')};
`;

export const LinkButton = styled(Button)<ButtonProps>`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1.5px solid ${getColor('surface-interactive-warning')};
  border-radius: 8px;
  outline: none;
`;

export const UpgradeLinkButton = styled(LinkButton)`
  :hover {
    border-width: 2px;
    text-decoration: none;
  }

  :focus-visible {
    outline: auto;
    outline-color: ${getColor('surface-interactive-warning')};
  }
`;
