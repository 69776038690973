/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { StatsAnalyticsPlusAdItemProps } from './StatsAnalyticsPlusAdItem.decl';

import { SidenavDropdownItem, SidenavDropdownItemLabel } from '@aircall/tractor-v2';
import { ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS } from '@components/AnalyticsPlusAdModal/AnalyticsPlusAdModal.decl';
import { generateUniqId } from '@dashboard/library';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export function StatsAnalyticsPlusAdItem({
  dataTest,
  onClick,
}: Readonly<StatsAnalyticsPlusAdItemProps>) {
  const { t } = useTranslation();
  const { track } = useTracker();

  useEffect(() => {
    track({ event: ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS.BUTTON_SHOWN });
  }, [track]);

  function handleOnClick() {
    const funnelId = generateUniqId();

    onClick(funnelId);

    track({
      event: ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS.BUTTON_CLICKED,
      payload: { funnel_id: funnelId },
    });
  }

  return (
    <SidenavDropdownItem data-test={dataTest} onClick={handleOnClick} as='button' type='button'>
      <SidenavDropdownItemLabel>{t('stats.tabs.discover_analytics_plus')}</SidenavDropdownItemLabel>
    </SidenavDropdownItem>
  );
}
