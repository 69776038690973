import { Banner, BannerHeading, BannerIcon, Flex, getColor } from '@aircall/tractor-v2';
import { LegacyDashboard } from '@components/LegacyDashboard';
import { ExternalLink } from '@components/Link';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { checkIsLiveMonitoringPlusEnabled } from '@dashboard/library';
import { checkIsLiveMonitoringAvailable } from '@helpers/liveMonitoring.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import styled, { css } from '@xstyled/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const linkStyles = css`
  color: ${getColor('informative-500')};

  &:hover {
    color: ${getColor('informative-700')};
  }
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledExternalLink = styled(ExternalLink)`
  ${linkStyles}
`;

export function ActivityFeed() {
  const { t } = useTranslation();
  const {
    dashboardExtensionEnableLiveMonitoringExtension,
    showLivefeedDeprecationMessage,
    showLookerInAnalytics,
  } = useFeaturesFlags();
  const { currentCompany } = useDashboardExtensionContext();

  const featureFlags = {
    dashboardExtensionEnableLiveMonitoringExtension,
    showLookerInAnalytics,
  } as Record<string, boolean>;
  const isLiveMonitoringAvailable = checkIsLiveMonitoringAvailable(featureFlags, currentCompany);
  const isLiveMonitoringPlusEnabled = checkIsLiveMonitoringPlusEnabled(featureFlags);
  const isBannerVisible = isLiveMonitoringAvailable && showLivefeedDeprecationMessage;

  return (
    <Flex flexDirection='column' h='100%'>
      <Flex flexDirection='column' flexGrow={1} overflow='hidden'>
        {isBannerVisible && (
          <Banner data-test='activity-feed-deprecation-banner' variant='info'>
            <BannerIcon />
            <BannerHeading>
              <Trans
                i18nKey='activity_feed.deprecation_message.text'
                components={[
                  <StyledLink
                    key={0}
                    to={
                      isLiveMonitoringPlusEnabled
                        ? '/live_monitoring_plus/calls'
                        : '/live_monitoring/calls'
                    }
                  />,
                  <StyledExternalLink
                    key={1}
                    href={t('activity_feed.deprecation_message.link')}
                    rel='noopener noreferrer'
                    target='_blank'
                  />,
                ]}
              />
            </BannerHeading>
          </Banner>
        )}
        <LegacyDashboard componentName={t('embedded_dashboard.components.activity_feed')} />
      </Flex>
    </Flex>
  );
}
