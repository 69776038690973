export const MAX_NUMBER_OF_TEAMS_ADVANCED = 10000;
export const MAX_NUMBERS_OF_NUMBERS_IN_TRIAL = 10;

export type AdminFeatures = {
  isAdvancedAnalyticsAllowed: boolean;
  isSipSettingsAllowed: boolean;
  isLiveFeedAllowed: boolean;
  isMandatoryTaggingAllowed: boolean;
  isQueueCallbackAllowed: boolean;
  maxNumberOfTeams: number;
  maxNumberOfNumbers: number;
  isCompanyAccountAllowed: boolean;
  isSupportLinksAllowed: boolean;
};
