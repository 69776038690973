import { TrialChecklistProgressProps } from './TrialChecklistProgress.decl';
import {
  TrialChecklistProgressWrapper,
  BackgroundCircle,
  ProgressCircle,
  TrialChecklistTextWrapper,
} from './TrialChecklistProgress.styles';

import { TickOutlined } from '@aircall/icons';
import { Icon } from '@aircall/tractor-v2';

export function TrialChecklistProgress({
  total,
  completed,
}: Readonly<TrialChecklistProgressProps>) {
  const progress = (completed / total) * 100;

  return (
    <TrialChecklistProgressWrapper>
      <svg width='35' height='35'>
        {/* Background Circle */}
        <BackgroundCircle />

        {/* Progress Circle */}
        <ProgressCircle progress={progress} />
      </svg>
      <TrialChecklistTextWrapper data-test='trial-checklist-text-wrapper'>
        {progress === 100 ? (
          <Icon
            component={TickOutlined}
            color='#000'
            size={18}
            data-test='trial-checklist-completed-icon'
          />
        ) : (
          `${completed}/${total}`
        )}
      </TrialChecklistTextWrapper>
    </TrialChecklistProgressWrapper>
  );
}
