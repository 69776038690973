import { Flex } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const TrialChecklistButtonBox = styled(Flex)`
  width: 56px;
  height: 56px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: #fbfbfb;
  cursor: pointer;
`;
