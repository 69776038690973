/* istanbul ignore file */
import aircall from './resellers/aircall';
import verizon from './resellers/verizon';

import { DefaultTheme } from '@aircall/tractor-v2';
import { APP_CONFIG, DOMAIN_NAME } from '@constants/environment.constants';

const resellers = [aircall, verizon];

export interface ResellerConfigType {
  name: string;
  appName: string;
  faviconUrl: string;
  logoUrl: string;
  downloadAppUrl: string;
  downloadLinkIos: string;
  cookieDomain: string;
  backendIssuedCookieDomain: string;
  downloadLinkAndroid: string;
  legacyDashboardUrl: string;
  featureSet: {
    shouldShowSignupLink: boolean;
    shouldShowReferAFriendButton: boolean;
    shouldShowGoogleSignIn: boolean;
    shouldShowFeedbackButton: boolean;
    shouldShowHelpLinks: boolean;
    shouldShowCompanyAccountPage: boolean;
    shouldShowFirefoxExtension: boolean;
    shouldShowEdgeExtension: boolean;
    shouldShowOnboardingPortal: boolean;
    shouldShowOnboardingWebinars: boolean;
    shouldShowLoginBackgroundImage: boolean;
    shouldShowSidebarBottomLogo: boolean;
    enableSaml: boolean;
    useEmailMfa: boolean;
  };
  identifier: string;
  getAssetPath: (assetName: string) => string;
  theme?: {
    v2: DefaultTheme;
  };
}

enum ENVIRONMENT_CONSTANTS {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  QA = 'qa',
  PRODUCTION = 'production',
}

const getAssetPath = (assetIdentifier: string) => (assetName: string) =>
  `${assetName}_${assetIdentifier}`;

export function getResellerConfig(): ResellerConfigType {
  const { legacyDashboardUrl, cookieDomain, backendIssuedCookieDomain, ...rest } =
    resellers.find((reseller) => reseller.domainList.includes(window.location.hostname)) || aircall;

  return {
    ...rest,
    cookieDomain:
      DOMAIN_NAME === 'netlify'
        ? window.location.host
        : DOMAIN_NAME || cookieDomain[APP_CONFIG.environment as ENVIRONMENT_CONSTANTS],
    backendIssuedCookieDomain:
      DOMAIN_NAME === 'netlify'
        ? backendIssuedCookieDomain.staging
        : backendIssuedCookieDomain[APP_CONFIG.environment as ENVIRONMENT_CONSTANTS],
    legacyDashboardUrl:
      process.env.REACT_APP_LEGACY_DASHBOARD_URL ||
      legacyDashboardUrl[APP_CONFIG.environment as ENVIRONMENT_CONSTANTS],
    getAssetPath: getAssetPath(rest.assetsIdentifier),
    identifier: rest.assetsIdentifier,
  };
}

export const ResellerConfig = getResellerConfig();
