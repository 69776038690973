import { css } from '@xstyled/styled-components';

/**
 * Necessary inline-end offset to prevent outline from being clipped by scrollbar.
 */
export const INLINE_END_OUTLINE_OFFSET = 6;

/**
 * Used to prevent outline from being clipped by scrollbar.
 */
export const paddingInlineEndForOutlineOffset = css`
  @supports (scrollbar-gutter: stable) {
    padding-inline-end: ${INLINE_END_OUTLINE_OFFSET}px;
  }
`;
