import { ADDONS } from '@constants/addons.constants';
import { TFunction } from 'react-i18next';

export function addonDataTest(addonName: ADDONS): string {
  return `button-${addonName.replace(/_/g, '-')}-trial-information-sidebar`;
}

export function getAddonDurationLabel(remainingDays: number, t: TFunction): string {
  if (!remainingDays) {
    return t('addons.sidenav.trial_finishes_today');
  }

  return t('addons.sidenav.trial_days_left', {
    count: remainingDays,
  });
}
