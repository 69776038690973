import { useCallback, useMemo } from 'react';

import { PageFunctionParams, TrackFunctionParams, UseTrackerReturn } from './useTracker.decl';

import { gainsight } from '@constants/gainsight.constants';
import { tracker } from '@constants/tracker.constants';
import { getCommonPayload, getSignedInAsPayload } from '@helpers/tracker.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import noop from 'lodash-es/noop';

/**
 * Hook providing a track utility function using Aircall tracker library.
 * @returns an object holding track function utility
 */
export function useTracker(): UseTrackerReturn {
  const { disableFrontendTracking } = useFeaturesFlags();

  const track = useCallback(({ event, payload = {} }: TrackFunctionParams) => {
    tracker.track(event, {
      ...getCommonPayload(),
      ...getSignedInAsPayload(),
      ...payload,
    });
    gainsight.track(event, {
      ...getCommonPayload(),
      ...getSignedInAsPayload(),
      ...payload,
    });
  }, []);

  const page = useCallback((payload: PageFunctionParams) => {
    tracker.page({
      ...getSignedInAsPayload(),
      ...payload,
    });
  }, []);

  return useMemo(
    () => ({
      track: disableFrontendTracking ? noop : track,
      page: disableFrontendTracking ? noop : page,
    }),
    [disableFrontendTracking, page, track]
  );
}
