import { useMemo } from 'react';

import { Flex } from '@aircall/tractor-v2';
import {
  GetIntegrationsToUpdateQuery,
  GetIntegrationsToUpdateQueryVariables,
} from '@generated/GetIntegrationsToUpdateQuery';
import { INTEGRATION_AVAILABLE_UPDATE_QUERY } from '@graphql/queries/IntegrationAvailableUpdateQuery';
import { useGraphQuery } from '@hooks/useQuery';

export function IntegrationSidenavBadge() {
  const { data } = useGraphQuery<
    GetIntegrationsToUpdateQuery,
    GetIntegrationsToUpdateQueryVariables
  >(INTEGRATION_AVAILABLE_UPDATE_QUERY, {
    variables: {
      requireUpdate: true,
    },
  });

  const integrationsToUpdate = useMemo(() => {
    const updateIntegration = (data?.getIntegrations || []).filter((integration) => {
      const { active, expiresAt } = integration;

      if (!expiresAt) {
        return false;
      }

      return active && Date.now() < new Date(expiresAt).getTime();
    });

    return updateIntegration;
  }, [data?.getIntegrations]);

  return integrationsToUpdate && !!integrationsToUpdate.length ? (
    <Flex
      data-test='integration-side-badge'
      backgroundColor='text-info'
      borderRadius='full'
      h={14}
      justifyContent='center'
      w={14}
    />
  ) : null;
}
