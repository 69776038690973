import React, { createContext, useMemo } from 'react';

import { GlobalContextType, GlobalCurrentUser } from './global.decl';

import { ASSETS_PAGE_ROUTE } from '@constants/regex.constants';
import { Loading } from '@dashboard/library';
import { GlobalDataQuery } from '@generated/GlobalDataQuery';
import { GLOBAL_DATA_QUERY } from '@graphql/queries/GlobalDataQuery';
import { useCurrentUser } from '@hooks/useCurrentUser/useCurrentUser';
import { useEntitiesCount } from '@hooks/useEntitiesCount/useEntitiesCount';
import { useGTM } from '@hooks/useGTM/useGTM';
import { useLogger } from '@hooks/useLogger';
import { usePathnameMatch } from '@hooks/usePathnameMatch';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

export const GlobalDataContext = createContext<GlobalContextType | undefined>(undefined);

export function GlobalDataProvider({ children }: { children: React.ReactNode }) {
  const skip = usePathnameMatch([ASSETS_PAGE_ROUTE]);
  const { currentUser } = useCurrentUser();

  const { data } = useGraphQuery<GlobalDataQuery>(GLOBAL_DATA_QUERY, {});
  const entitiesCount = useEntitiesCount({ skip });

  const globalData = useMemo(() => {
    if (!data?.currentCompany || !currentUser || !data?.companySettings) {
      return null;
    }

    const { currentCompany, companySettings } = data;

    /* istanbul ignore next  */
    const user: GlobalCurrentUser = {
      ...currentUser,
      language: currentUser.language ?? 'en-US',
    };

    const currentPlan = companySettings.plans.find((plan) => plan.name === currentCompany.plan)!;

    const isTrial = !!data.currentCompany.trial;

    return {
      currentUser: user,
      currentCompany,
      companySettings,
      isTrial,
      currentPlan,
      entitiesCount,
    };
  }, [currentUser, data, entitiesCount]);

  useLogger(globalData?.currentUser, globalData?.currentCompany);

  useGTM({
    companyId: globalData?.currentCompany.id,
    userId: globalData?.currentUser.ID,
  });

  if (!globalData) {
    return <Loading data-test='global-provider-loading' />;
  }

  return <GlobalDataContext.Provider value={globalData}>{children}</GlobalDataContext.Provider>;
}
