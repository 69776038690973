import React from 'react';

import { DiscoverCard } from './DiscoverCard';

import { Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { ReactComponent as AddTeamIcon } from '@assets/icons/add_team_icon.svg';
import { ReactComponent as CallIcon } from '@assets/icons/call_icon.svg';
import { ReactComponent as StatsIcon } from '@assets/icons/stats_icon.svg';
import { STATS_CHILD_ROUTE, NUMBERS_ROUTE } from '@constants/routes.constants';
import { WORKSPACE_URL } from '@constants/urls.constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function DiscoverMoreSection() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleAddTeamClick = () => {
    window.open(`${WORKSPACE_URL}contacts`);
  };

  const handleCallIconClick = () => {
    navigate(NUMBERS_ROUTE);
  };

  const handleStatsClick = () => {
    navigate(STATS_CHILD_ROUTE.OVERVIEW);
  };

  const discoverCardsData = [
    {
      heading: t('onboarding.discover_more_section.invite_link.heading'),
      subHeading: t('onboarding.discover_more_section.invite_link.sub_heading'),
      SvgIcon: AddTeamIcon,
      handleClick: handleAddTeamClick,
    },
    {
      heading: t('onboarding.discover_more_section.call_link.heading'),
      subHeading: t('onboarding.discover_more_section.call_link.sub_heading'),
      SvgIcon: CallIcon,
      handleClick: handleCallIconClick,
    },
    {
      heading: t('onboarding.discover_more_section.analytics_link.heading'),
      subHeading: t('onboarding.discover_more_section.analytics_link.sub_heading'),
      SvgIcon: StatsIcon,
      handleClick: handleStatsClick,
    },
  ];

  return (
    <Flex flexDirection='column' w='100%' mt='s'>
      <Typography data-test='discover-more-heading' variant='headingBoldS'>
        {t('onboarding.discover_more_section.heading')}
      </Typography>
      <Typography data-test='discover-more-subheading' mt='15px' mb='10px' variant='bodyRegularM'>
        {t('onboarding.discover_more_section.sub_heading')}
      </Typography>
      <Spacer space='xxs' direction='vertical'>
        {discoverCardsData.map((card) => (
          <DiscoverCard
            key={card.heading}
            heading={card.heading}
            subHeading={card.subHeading}
            icon={card.SvgIcon}
            handleClick={card.handleClick}
          />
        ))}
      </Spacer>
    </Flex>
  );
}
