/* eslint-disable @typescript-eslint/naming-convention */
import { TrialChecklistPopupItemType } from './TrialChecklistPopup.decl';

import {
  BookmarkFilled,
  CallFilled,
  OnboardingFilled,
  PeopleFilled,
  UserFilled,
} from '@aircall/icons';
import { ReactComponent as Hubspot } from '@assets/icons/hubspot_icon.svg';
import { ReactComponent as IntegrationFilled } from '@assets/icons/integration_fill.svg';
import { StepType } from '@generated/globalTypes';

export const STEP_TYPE_REDIRECT_MAP: Record<StepType, string> = {
  [StepType.LAUNCH_PHONE]: 'https://phone.aircall.io',
  [StepType.MAKE_CALL]: 'https://phone.aircall.io',
  [StepType.SEND_MESSAGE]: 'https://phone.aircall.io',
  [StepType.TEST_INTEGRATION]: '/generic-integration',
  [StepType.LAUNCH_DASHBOARD]: '/onboarding',
  [StepType.ADD_ANOTHER_USER]: '/users',
  [StepType.CREATE_TEAM]: '/teams',
};

export const checklistData: TrialChecklistPopupItemType[] = [
  {
    titleKey: 'user_profile.task_checklist.launch_app',
    stepType: StepType.LAUNCH_PHONE,
    icon: UserFilled,
    descriptionKey: 'user_profile.task_checklist.launch_app_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.make_call',
    stepType: StepType.MAKE_CALL,
    icon: CallFilled,
    descriptionKey: 'user_profile.task_checklist.make_call_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.launch_dashboard',
    stepType: StepType.LAUNCH_DASHBOARD,
    icon: OnboardingFilled,
    descriptionKey: 'user_profile.task_checklist.launch_dashboard_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.add_another_user',
    stepType: StepType.ADD_ANOTHER_USER,
    icon: UserFilled,
    descriptionKey: 'user_profile.task_checklist.add_another_user_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.create_team',
    stepType: StepType.CREATE_TEAM,
    icon: PeopleFilled,
    descriptionKey: 'user_profile.task_checklist.create_team_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.test_integrations',
    stepType: StepType.TEST_INTEGRATION,
    icon: Hubspot,
    descriptionKey: 'user_profile.task_checklist.test_integrations_desc',
  },
];

export const checklistDataV2: TrialChecklistPopupItemType[] = [
  {
    ...checklistData[0],
    icon: BookmarkFilled,
  },
  {
    ...checklistData[1],
  },
  {
    ...checklistData[2],
  },
  {
    ...checklistData[3],
  },
  {
    ...checklistData[4],
  },
  {
    ...checklistData[5],
    icon: IntegrationFilled,
  },
];
