import { LinePositions } from './LinePositions';
import { STEP_TYPE_REDIRECT_MAP, checklistData } from './TrialChecklistData';
import { TrialChecklistItemActionButtons } from './TrialChecklistItemActionButtons';
import { TrialChecklistPopupItemProps } from './TrialChecklistPopup.decl';

import { Flex, Icon, Typography, getColor } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { StepStatus } from '@generated/globalTypes';
import { useOnboardingData } from '@hooks/useOnboardingData';
import styled from '@xstyled/styled-components';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { useTranslation } from 'react-i18next';

const CustomTodoItem = styled(Flex)`
  border: 3px solid transparent;

  &:hover,
  &:active,
  &:focus {
    border: 3px solid ${getColor('text-interactive-primary')};
  }
`;

const CustomExternalLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

const FireworksCanvas = styled(Fireworks)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
`;

export function TrialChecklistPopupItem({
  icon,
  stepType,
  index,
  titleKey,
}: Readonly<TrialChecklistPopupItemProps>) {
  const { t } = useTranslation();
  const { onboardingData } = useOnboardingData();
  const onboardingStepData = onboardingData?.steps.find((step) => step.stepType === stepType);
  const url = onboardingStepData?.redirectUrl ?? STEP_TYPE_REDIRECT_MAP[stepType];
  const isComplete = onboardingStepData?.stepStatus === StepStatus.COMPLETED;
  const title = onboardingStepData?.stepTitle ?? t(titleKey);

  return (
    <Flex
      justifyContent='space-between'
      alignItems='center'
      paddingLeft='6px'
      paddingBottom={10}
      position='relative'
      data-test='checklist-item'
    >
      <LinePositions
        isComplete={onboardingStepData?.stepStatus === StepStatus.COMPLETED}
        indexPosition={index}
        isActive={index === 0}
        skipTopLine={index === 0}
        skipBottomLine={index + 1 === checklistData.length}
      />
      <CustomTodoItem
        backgroundColor='surface-interactive-default'
        w={284}
        justifyContent='space-between'
        alignItems='center'
        paddingRight='16px'
        borderRadius={8}
        boxShadow='0px 2px 2px 0px rgba(0, 0, 0, 0.08)'
        opacity={isComplete ? 0.8 : 1}
        position='relative'
        data-test='checklist-item-wrapper'
      >
        {isComplete ? (
          <FireworksCanvas
            autorun={{ speed: 0.35, duration: 10000 }}
            globalOptions={{
              disableForReducedMotion: true,
            }}
          />
        ) : undefined}

        <CustomExternalLink href={url} padding='12px 0 12px 16px'>
          <Flex alignItems='center'>
            <Icon
              component={icon}
              color='icon-primary'
              size={16}
              data-test='checklist-item-left-icon'
              marginRight={10}
            />
            <Flex w={185} flexDirection='column' alignItems='flex-skipTopLine'>
              <Typography variant='bodyMediumS' color='text-base' data-test='checklist-item-title'>
                {title}
              </Typography>
            </Flex>
          </Flex>
        </CustomExternalLink>
        <TrialChecklistItemActionButtons stepType={stepType} isComplete={isComplete} />
      </CustomTodoItem>
    </Flex>
  );
}
