import { useCallback, useEffect } from 'react';

import { Spacer, Typography, Avatar, AvatarImg } from '@aircall/tractor-v2';
import { COOKIE_KEYS } from '@constants/auth.constants';
import { SANDBOX_DOMAIN_WHITELIST } from '@constants/environment.constants';
import { HOME_ROUTE } from '@constants/routes.constants';
import { FormWizard, getFullName } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useCurrentUser } from '@hooks/useCurrentUser/useCurrentUser';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

interface LocationState {
  hasSignedIn: boolean;
}

/**
 * This page is meant to work only in staging and dev environments.
 * Its goal is to act as an SSO gateway for the sandbox.
 * The sandbox will redirect to this page (on the staging env) whenever it needs to
 * authenticate the user and this page will redirect to the provided URL by
 * appending the token in the query parameters.
 */
export function SandboxSSOPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    actions: { signOut },
    authState: { token },
  } = useAuthenticationState();
  const { data: userData } = useCurrentUser();

  const hasSignedIn = (location.state as LocationState)?.hasSignedIn;

  const authenticate = useCallback(() => {
    const redirectURL = searchParams.get('redirect');

    // If there's no redirect URL we redirect to the homepage
    if (redirectURL === null) {
      navigate(HOME_ROUTE);
      return;
    }

    const parsedURL = new URL(redirectURL);

    // Takes only the domain part of the hostanme
    const domain = parsedURL.hostname.split('.').reverse().slice(0, 2).reverse().join('.');

    // If the domain is not whitelisted we just redirect to the homepage
    if (!SANDBOX_DOMAIN_WHITELIST.includes(domain)) {
      navigate(HOME_ROUTE);
      return;
    }

    // We redirect to the given page while appending the
    // token in the query string
    parsedURL.searchParams.append('token', token ?? '');
    parsedURL.searchParams.append(
      'refresh_token',
      (Cookies.get(COOKIE_KEYS.AC_REFRESH_TOKEN) || Cookies.get(COOKIE_KEYS.REFRESH_TOKEN)) ?? ''
    );
    window.location.replace(parsedURL.toString());
  }, [searchParams, token, navigate]);

  useEffect(() => {
    // If we come from the login page, we redirect directly
    if (hasSignedIn) {
      authenticate();
    }
  }, [authenticate, hasSignedIn]);

  if (hasSignedIn || !userData) {
    return null;
  }

  const { getAgentV2: currentUser } = userData;

  // If we come from the login page, we don't display anything.
  // Otherwise we display a confirmation screen reminding with
  // witch account the user is currently connected.
  // Then, we let him the choice to keep going with this account
  // or to connect with another one
  return (
    <FormWizard onSuccess={authenticate} onClose={signOut} initialData={{ foo: null }}>
      <FormWizard.Header>
        <FormWizard.HeaderTitle>{t('sandbox_sso.title')}</FormWizard.HeaderTitle>
      </FormWizard.Header>

      <FormWizard.Pages>
        <FormWizard.Page stepTitle='Prepare your file'>
          <FormWizard.PageHeader>
            <FormWizard.PageHeaderSubtitle>
              {t('sandbox_sso.subtitle')}
            </FormWizard.PageHeaderSubtitle>
          </FormWizard.PageHeader>

          <FormWizard.PageContent>
            <Spacer space='s' alignItems='center'>
              {currentUser.pictureURL && (
                <Avatar data-test='user-avatar' size='xLarge' variant='default'>
                  <AvatarImg src={currentUser.pictureURL} />
                </Avatar>
              )}

              <Spacer direction='vertical'>
                <Typography variant='headingLightM' data-test='sandbox-sso-fullname'>
                  {getFullName(currentUser.firstName, currentUser.lastName)}
                </Typography>

                <Typography variant='bodyRegularS' color='grey.dark' data-test='sandbox-sso-email'>
                  {currentUser.email}
                </Typography>
              </Spacer>
            </Spacer>
          </FormWizard.PageContent>

          <FormWizard.PageFooter>
            <FormWizard.PageFooterPrev onClick={signOut}>
              {t('sandbox_sso.buttons.cancel')}
            </FormWizard.PageFooterPrev>

            <FormWizard.PageFooterNext onClick={authenticate}>
              {t('sandbox_sso.buttons.next')}
            </FormWizard.PageFooterNext>
          </FormWizard.PageFooter>
        </FormWizard.Page>
      </FormWizard.Pages>
    </FormWizard>
  );
}
