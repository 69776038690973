import { BlockedNumbersSection } from './BlockedNumbersSection/BlockedNumbers.section';

import { Page, PageContent } from '@components/Page/Page';
import { PageHeader } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function CallsBlockedNumbersPage() {
  const { t } = useTranslation();

  return (
    <Page>
      <PageHeader
        data-test='tags-page-header'
        largeTitle={t('calls.pages.blocked_numbers.title')}
      />
      <PageContent>
        <BlockedNumbersSection />
      </PageContent>
    </Page>
  );
}
