const verizonConfig = {
  name: 'Verizon',
  appName: 'Contact Center Hub',
  faviconUrl: 'https://cdn.aircall.io/icos/verizon_favicon.ico',
  logoUrl: 'https://cdn.aircall.io/icos/logo-verizon.ico',
  downloadAppUrl:
    'https://b2b.verizonwireless.com/content/my-business-portal/support/howtoguides/contact_center_hub_apps.html',
  downloadLinkIos: 'https://apps.apple.com/us/app/id1530223537',
  downloadLinkAndroid:
    'https://play.google.com/store/apps/details?id=com.verizon.contactcenterhub&hl=en',
  domainList: [
    'dashboard-verizon-v4.aircall-staging.com',
    'dashboard-verizon-v4.aircall.io',
    'dashboard-verizon.aircall.io',
    'dashboard-verizon.aircall-staging.com',
    'dashboard-verizon.aircall-qa.com',
    'verizon-local.aircall-develop.com',
  ],
  cookieDomain: {
    development: 'localhost',
    staging: 'aircall-staging.com',
    production: 'aircall.io',
    qa: 'aircall-qa.com',
  },
  backendIssuedCookieDomain: {
    development: 'localhost',
    staging: '.aircall-staging.com',
    production: '.aircall.io',
    qa: '.aircall-qa.com',
  },
  legacyDashboardUrl: {
    development: 'http://localhost:4000/#/',
    staging: 'https://dashboard-verizon-embed.aircall-staging.com/',
    qa: 'https://dashboard-verizon-embed.aircall-qa.com/',
    production: 'https://dashboard-verizon-embed.aircall.io/',
  },
  featureSet: {
    shouldShowSignupLink: false,
    shouldShowReferAFriendButton: false,
    shouldShowGoogleSignIn: false,
    shouldShowFeedbackButton: false,
    shouldShowHelpLinks: false,
    shouldShowCompanyAccountPage: false,
    shouldShowFirefoxExtension: false,
    shouldShowEdgeExtension: false,
    shouldShowOnboardingPortal: false,
    shouldShowOnboardingWebinars: false,
    shouldShowLoginBackgroundImage: true,
    shouldShowSidebarBottomLogo: true,
    enableSaml: false,
    useEmailMfa: false,
  },
  assetsIdentifier: 'verizon',
  theme: {
    v1: {
      base: {
        white: '#ffffff',
        black: '#000000',
      },
      primary: {
        darker: '#150F10',
        dark: '#191516',
        base: '#1E1E1E',
        light: '#787878',
        lighter: '#BBBBBB',
      },
      secondary: {
        darker: '#990F1C',
        dark: '#B71517',
        base: '#D52B1E',
        light: '#E56851',
        lighter: '#F29275',
      },
      grey: {
        darker: '#595952',
        dark: '#86867C',
        base: '#D8DADA',
        light: '#E8E8E6',
        lighter: '#F7F7F7',
      },
      green: {
        darker: '#004030',
        dark: '#006036',
        base: '#008631',
        light: '#57DA6D',
        lighter: '#C8F8C7',
      },
      blue: {
        darker: '#002358',
        dark: '#004684',
        base: '#007AB8',
        light: '#5DCCE9',
        lighter: '#C9F7FB',
      },
      yellow: {
        darker: '#7A440B',
        dark: '#B7771E',
        base: '#FFBC3D',
        light: '#FFDF8A',
        lighter: '#FFF7D8',
      },
      red: {
        darker: '#761304',
        dark: '#AF360A',
        base: '#CC4D0F',
        light: '#EFA76A',
        lighter: '#F9CD9D',
      },
      purple: {
        darker: '#312A5B',
        dark: '#493F89',
        base: '#6254B6',
        light: '#AFA5EF',
        lighter: '#E4E1FA',
      },
      peach: {
        darker: '#A53500',
        dark: '#FF854C',
        base: '#FFBE9F',
        light: '#FFD8C5',
        lighter: '#FCEFE9',
      },
      ghost: {
        darker: 'rgba(255, 255, 255, 0.1)',
        dark: 'rgba(255, 255, 255, 0.2)',
        base: 'rgba(255, 255, 255, 0.4)',
        light: 'rgba(255, 255, 255, 0.6)',
        lighter: 'rgba(255, 255, 255, 0.8)',
      },
      darkGhost: {
        darker: 'rgba(217, 217, 214, 0.1)',
        dark: 'rgba(217, 217, 214, 0.2)',
        base: 'rgba(217, 217, 214, 0.4)',
        light: 'rgba(217, 217, 214, 0.6)',
        lighter: 'rgba(217, 217, 214, 0.8)',
      },
      text: {
        darker: '#150F10',
        dark: '#787878',
        base: '#1E1E1E',
        light: '#BBBBBB',
        lighter: '#FFFFFF',
      },
    },
  },
};

export default verizonConfig;
