export interface PlanInterface {
  slug: string;
  color: string;
  name: string;
  description: string;
  featuresTitle: string;
  features: string[];
  featuresDescription?: string;
  featuresDescription2?: string;
}

export interface PriceInterface {
  starter: {
    annually: string;
    monthly: string;
  };
  essentials: {
    annually: string;
    monthly: string;
  };
  professional: {
    annually: string;
    monthly: string;
  };
}

export enum PLANS_MODAL_TRACK_EVENTS {
  MODAL_SHOWN = 'plans_modal_shown',
  MODAL_DISMISSED = 'plans_modal_dismissed',
  CONTINUE_TRIALING_CLICKED = 'plans_modal_continue_trialing_clicked',
  UPGRADE_NOW_CLICKED = 'plans_modal_upgrade_now_clicked',
}

export const SHOW_STARTER = 'show_starter';
export const HIDE_STARTER = 'hide_starter';
