import { useMemo } from 'react';

import styled from 'styled-components';

import { AddonTrialBadgeProps } from './AddonTrialBadge.decl';

import { Box, Typography, Button, getColor, getSpace, ButtonProps } from '@aircall/tractor-v2';
import { ReactComponent as AdvancedMessagingIcon } from '@assets/icons/advanced_messaging_icon.svg';
import { ReactComponent as AircallAIIcon } from '@assets/icons/aircall_ai_icon.svg';
import { ReactComponent as AircallSurveysIcon } from '@assets/icons/aircall_surveys_icon.svg';
import { ReactComponent as AnalyticsPlusIcon } from '@assets/icons/sidenav/analytics_plus.svg';
import { ADDONS } from '@constants/addons.constants';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { useNavigateToAddonPage } from '@dashboard/library';
import { Trans, useTranslation } from 'react-i18next';

const TrialButton = styled(Button)<ButtonProps>`
  justify-content: start;
  border-radius: 0;
  gap: ${getSpace('xxxs')};
  padding-block: ${getSpace('s')};
  padding-inline: 20px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${getColor('graphic-default')};
  }
`;

function getAddonTrialIcon(name: ADDONS) {
  switch (name) {
    case ADDONS.AIRCALL_AI:
      return <AircallAIIcon />;
    case ADDONS.ADVANCED_MESSAGING:
      return <AdvancedMessagingIcon />;
    case ADDONS.AIRCALL_SURVEYS:
      return <AircallSurveysIcon />;
    case ADDONS.ANALYTICS_PLUS:
    default:
      return <AnalyticsPlusIcon />;
  }
}

export function AddonTrialBadge({ name, remainingDays }: Readonly<AddonTrialBadgeProps>) {
  const { t } = useTranslation();
  const { track } = useDashboardExtensionContext();

  const navigateToAddonPage = useNavigateToAddonPage(track);

  const trackEvent = useMemo(
    () => `button-${name.replace(/_/g, '-')}-trial-information-sidebar`,
    [name]
  );

  return (
    <TrialButton
      mode='ghost'
      onClick={() => navigateToAddonPage(name, trackEvent)}
      data-test={trackEvent}
      block
    >
      <Box style={{ flexShrink: 0 }}>{getAddonTrialIcon(name)}</Box>
      <Box minWidth='0' style={{ wordBreak: 'break-word', textAlign: 'start' }}>
        {remainingDays ? (
          <Typography variant='supportingSemiboldS' color='text-base' as='p' margin='0'>
            {t('addons.side_menu.trial_ongoing_label', {
              count: remainingDays,
              addonName: t(`addons.${name}.name`),
            })}
          </Typography>
        ) : (
          <Typography variant='supportingSemiboldS' color='text-base' as='p' margin='0'>
            <Trans
              i18nKey='addons.side_menu.trial_finishes_today_label'
              values={{
                addonName: t(`addons.${name}.name`),
              }}
              components={[
                <Typography
                  key={0}
                  variant='supportingSemiboldS'
                  color='text-interactive-critical'
                  as='span'
                />,
              ]}
            />
          </Typography>
        )}
        <Typography variant='supportingRegularXS' color='text-base' as='p' margin='0'>
          {t(`addons.${name}.motto`)}
        </Typography>
      </Box>
    </TrialButton>
  );
}
