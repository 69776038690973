import { Flex, SidenavItem } from '@aircall/tractor-v2';
import { INLINE_END_OUTLINE_OFFSET } from '@components/Sidenav/Sidenav.utils';
import styled, { getSpace } from '@xstyled/styled-components';

interface StyledContainerProps {
  expanded: boolean;
}

export const StyledSidenavItem = styled(SidenavItem)`
  width: 56px;
  height: 56px;
`;

/* istanbul ignore next */
export const Container = styled(Flex)<StyledContainerProps>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getSpace('xxxs')};
  margin-bottom: 10px;

  @supports (scrollbar-gutter: stable) {
    margin-inline-end: ${INLINE_END_OUTLINE_OFFSET}px;
  }

  /*
   * we delay min-inline-size since we don't want to transition it
   */
  transition: inline-size ease 300ms, min-inline-size 300ms 0s;
`;
